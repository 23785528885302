import { Typography } from "antd"
import type { MapToken } from "antd/es/theme/interface"
import type { SeedToken } from "antd/es/theme/internal"
import { useTranslation } from "react-i18next"
import { importAll } from "./usefulScripts"
import { useLang } from "./App"

const { Title, Paragraph, Text } = Typography

const images = importAll(require.context("./img", false, /About.*/)) as { [key: string]: string }

function About() {
    const { t, i18n } = useTranslation()
    const { lang } = useLang()

    return (
        <div className="space-y-5 md:space-y-8" style={{ animation: "fadeIn 0.5s ease-in-out" }}>
            <div className="relative container md:mx-auto text-center py-10 md:py-16 w-full lg:w-[80vw]">
                <div className="space-y-10 md:space-y-16 xl:space-y-8">
                    <div className="text-left px-5 pb-5">
                        <div className="text-xl md:text-3xl font-extrabold">{t("about.Title")}</div>

                        <div className="text-base lg:text-base xl:text-lg mt-5">
                            <div style={{ textIndent: "0rem" }}>{t("about.Para1")}</div>
                        </div>
                    </div>

                    <div className="grid grid-cols-12 gap-6 lg:gap-12 p-5 items-center" style={{ border: "0px red solid" }}>
                        <div
                            className={`text-left col-span-12 ${
                                lang === "zh"
                                    ? "md:col-span-8 xl:col-span-8 order-1 md:order-0"
                                    : "lg:col-span-8 xl:col-span-8 order-[1] lg:order-[0]"
                            } grid`}
                        >
                            <div className="text-base lg:text-base xl:text-lg">
                                <div style={{ textIndent: "0rem" }}>{t("about.Para2")}</div>
                            </div>
                        </div>
                        <img
                            alt="about us"
                            src={images["About_Banner_1.png"]}
                            className={`mx-auto col-span-12 ${
                                lang === "zh"
                                    ? "md:col-span-4 xl:col-span-4 order-0 md:order-1"
                                    : "lg:col-span-4 xl:col-span-4 order-[0] lg:order-[1]"
                            } grid `}
                            // style={{ border: "1px red solid" }}
                            // height={"5vh"}
                        />
                    </div>

                    {lang === "zh" ? (
                        <div className="grid grid-cols-12 items-center bg-slate-50 gap-6 lg:gap-12 p-5">
                            <div className="text-left col-span-12 md:col-span-7 lg:col-span-8 xl:col-span-8 grid order-[1] md:order-[1]">
                                <div className="text-base lg:text-base xl:text-lg">{t("about.Para3_1.p1")}</div>
                            </div>
                            <img
                                alt="about us"
                                src={images["About_Banner_2.png"]}
                                className="mx-auto col-span-12  md:col-span-5 lg:col-span-4 xl:col-span-4 grid order-[0] md:order-[0]"
                            />
                        </div>
                    ) : (
                        <>
                            <div className="grid grid-cols-12 items-center bg-slate-100 gap-6 lg:gap-12 p-5">
                                <div className="text-left col-span-12 lg:col-span-7 grid order-[1] md:order-[1]">
                                    <div className="text-base lg:text-base xl:text-lg">{t("about.Para3_1.en_p1")}</div>
                                </div>
                                <img
                                    alt="about us"
                                    src={images["About_Banner_2.png"]}
                                    className="mx-auto col-span-12 lg:col-span-5 xl:col-span-4 rid order-[0] md:order-[0]"
                                />
                            </div>

                            <div className="grid grid-cols-12 gap-6 lg:gap-12 p-5 items-center">
                                <div className="text-left col-span-12 lg:col-span-8  grid order-[1] lg:order-[0]">
                                    <div className="text-base lg:text-base xl:text-lg">
                                        <span style={{ textIndent: "0rem" }}>{t("about.Para3_1.en_p2")}</span>
                                        <Text code>{t("about.Para3_2")}</Text>，<Text code>{t("about.Para3_3")}</Text>，
                                        <Text code>{t("about.Para3_4")}</Text>，<Text code>{t("about.Para3_5")}</Text>，
                                        <Text code>{t("about.Para3_6")}</Text>
                                        {t("about.Para3_7")}
                                        <Text code>{t("about.Para3_8")}</Text>
                                        {t("about.Para3_9")}
                                    </div>
                                </div>
                                <img
                                    alt="about us"
                                    src={images["About_Banner_3.jpg"]}
                                    className="mx-auto col-span-12 lg:col-span-4 grid order-[0] lg:order-[1]"
                                />
                            </div>
                        </>
                    )}

                    <div
                        className={`grid grid-cols-12 gap-6 lg:gap-12 p-5 items-center ${lang === "zh" ? "" : "bg-slate-100"}`}
                        // style={{ border: "1px red solid" }}
                    >
                        <div
                            className={`text-left col-span-12 grid  ${
                                lang === "zh"
                                    ? "order-1 md:order-0 md:col-span-7 lg:col-span-8 xl:col-span-8"
                                    : "order-[1] md:order-[1] lg:col-span-8"
                            } `}
                        >
                            <div className="text-base lg:text-base xl:text-lg">
                                <div style={{ textIndent: "0rem" }}>
                                    {lang === "zh" ? (
                                        <>
                                            {t("about.Para3_1.p2")}
                                            <Text code>{t("about.Para3_2")}</Text>，<Text code>{t("about.Para3_3")}</Text>，
                                            <Text code>{t("about.Para3_4")}</Text>，<Text code>{t("about.Para3_5")}</Text>，
                                            <Text code>{t("about.Para3_6")}</Text>
                                            {t("about.Para3_7")}
                                            <Text code>{t("about.Para3_8")}</Text>
                                            {t("about.Para3_9")}
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {t("about.Para3_10")}
                                    <Text code>{t("about.Para3_11")}</Text>
                                    {t("about.Para3_12")}
                                    <Text strong>{t("about.Para3_13")}</Text>
                                    {t("about.Para3_14")}
                                </div>
                            </div>
                        </div>
                        <img
                            alt="about us"
                            src={images["About_Banner_4.jpg"]}
                            className={`mx-auto col-span-12 grid ${
                                lang === "zh"
                                    ? "order-0 md:order-1 md:col-span-5 lg:col-span-4 xl:col-span-4"
                                    : "order-[0] md:order-[0] lg:col-span-4"
                            }`}
                            // style={{ border: "1px red solid" }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
