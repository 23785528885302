import type { MapToken } from "antd/es/theme/interface"
import type { SeedToken } from "antd/es/theme/internal"
import { getTreatmentEntry, importAll, TreatmentDataKeys } from "./usefulScripts"
import { Outlet, useNavigate, useNavigation } from "react-router-dom"
import { Divider, Image, Typography } from "antd"
import { useTranslation } from "react-i18next"
import ErrorPage from "./Error-page"

const images = importAll(require.context("./img", false, /(Treatment|Contact).*/)) as { [key: string]: string }
const { Title } = Typography
function Treatments(
    {
        // currentTheme,
        // }: {
        // currentTheme: { algorithm: (token: SeedToken) => MapToken }
    }
) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const typesOfTreatments = Object.keys(TreatmentDataKeys)

    return (
        <div className="space-y-5 md:space-y-8 bg-stone-0">
            {/* <div
                className="h-[25rem] mb-20 bg-stone-100 relative ps-[15%] pt-[5%]"
                style={{ backgroundSize: "cover", backgroundImage: `url(${images["Contact_Banner.png"]})` }}
            >
                <div className="">
                    <div className="tracking-wider font-light text-5xl">CONTACT US</div>
                    <div className="border-b w-10 border-black border-1 my-3"></div>
                    <div>sodsafbsoa</div>
                    <div></div>
                </div>
            </div> */}
            <section className="relative container md:mx-auto text-center py-10 md:py-16">
                <div
                    className="relative"
                    //space-y-5 xl:space-y-8
                >
                    <div className="text-2xl md:text-3xl font-extrabold">{t("treatment.Title")}</div>

                    {typesOfTreatments.map((type) => {
                        return (
                            <div key={type}>
                                <Divider>
                                    <div className="md:text-3xl font-semibold my-8">{t("treatment.TreatmentDataKeys." + type)}</div>
                                </Divider>
                                {/* <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3 gap-5 md:gap-10 lg:gap-16 "> */}
                                <div className="flex flex-row flex-wrap gap-5 md:gap-10 lg:gap-16 justify-center align-items-start">
                                    {TreatmentDataKeys[type].map((key) => {
                                        const entryData = getTreatmentEntry(key)
                                        if (!entryData) return <ErrorPage />
                                        const { imageUrl, Title1 } = entryData
                                        // console.log("title1:", Title1)
                                        return (
                                            <a
                                                key={t(Title1 as string)}
                                                className="basis-1/3 md:basis-1/4 flex flex-col justify-center gap-3 items-center mb-3"
                                                style={{ animation: "fadeIn 0.5s ease-in-out" }}
                                                href={`/treatments/${key}`}
                                            >
                                                <div className="relative overflow-hidden group rounded-full shadow-lg hover:scale-105">
                                                    <div className="absolute w-full h-full bg-cyan-700 bg-opacity-20 left-0 bottom-0 z-20 hidden group-hover:block transition-all duration-300 transform rounded-full" />
                                                    <Image
                                                        src={imageUrl as string}
                                                        // onClick={() => navigate("./${key}")}
                                                        width={"17.5vw"}
                                                        className="w-full rounded-full cursor-pointer "
                                                        preview={false}
                                                    />
                                                </div>

                                                <div className="md:text-lg font-semibold cursor-pointer" onClick={() => navigate(`./${key}`)}>
                                                    {t(Title1 as string)}
                                                </div>
                                            </a>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
            <Outlet />
        </div>
    )
}

export default Treatments
