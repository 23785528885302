import { Carousel } from "bootstrap"
import React from "react"
import { useTranslation } from "react-i18next"
import { Avatar, List, Typography, Rate, Divider } from "antd"
import { importAll } from "./usefulScripts"

const { Title, Text, Paragraph } = Typography

const images = importAll(require.context("./img", false, /(Experience|Review).*/)) as { [key: string]: string }

function Review() {
    const { t } = useTranslation()

    React.useEffect(() => {
        // Activate carousel auto play
        const carousel = new Carousel("#carouselExampleInterval")
    }, [])

    const data2 = [
        {
            title: "TYNEE THAM",
            avatar: images["Review_0.jpeg"],
            content: <div>{t("review.1.Text1")}</div>,
        },
        {
            title: "KAREN ONG",
            avatar: images["Review_1.jpeg"],
            content: (
                <>
                    <div>{t("review.2.Text1")}</div>
                    <div>{t("review.2.Text2")}</div>
                </>
            ),
        },
        {
            title: "LAU LAU",
            avatar: images["Review_2.jpeg"],
            content: (
                <>
                    <div>{t("review.3.Text1")}</div>
                    <div>{t("review.3.Text2")}</div>
                </>
            ),
        },
        {
            title: "巫式祥",
            avatar: images["Review_3.jpeg"],
            content: <div>{t("review.4.Text1")}</div>,
        },
    ]

    return (
        <>
            <div
                id="carouselExampleInterval"
                className="carousel carousel-dark slide mx-auto
                h-[24rem] 2xs:h-[28rem] sm:h-[50rem] lg:h-[50rem] 
                w-[100%]"
                data-bs-ride="carousel"
                // style={{ border: "1px solid green " }}
            >
                <div className="carousel-indicators">
                    <button
                        type="button"
                        data-bs-target="#carouselExampleInterval"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                    ></button>
                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div
                    className="carousel-inner carousel-fadee w-[100%] h-auto 
                    ps-[1.5%] 2xs:ps-[0vw] xs:ps-[12vw] sm:ps-[0vw] md:ps-[10vw] lg:ps-[15vw] xl:ps-[26vw] 2xl:ps-[29vw]"
                    // style={{ border: "1px solid red " }}
                >
                    {data2.map((d, _i) => {
                        return (
                            <div
                                key={`review_carousel-item-${_i}`}
                                className={`carousel-item ${_i === 0 ? "active" : " "} text-center
                                w-[23rem] 2xs:w-[27rem] sm:w-[45rem] 
                                h-[23rem] 2xs:h-[27rem] sm:h-[45rem] `}
                                data-bs-interval="100000000010000"
                                style={{
                                    // border: "1px solid red ",
                                    backgroundImage: `url(${images["Experience_ReviewTemplate.png"]})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                }}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            src={d.avatar}
                                            className="
                                            
                                    mt-[20%] 2xs:mt-[19%] sm:mt-[7.5rem] 
                                    w-[3rem] 2xs:w-[4rem] sm:w-[8rem] 
                                    h-[3rem] 2xs:h-[4rem] sm:h-[8rem]"
                                        />
                                    }
                                    className=""
                                    // style={{ border: "1px solid red" }}
                                />
                                <Rate
                                    disabled
                                    defaultValue={5}
                                    className="container
                                    mt-1 sm:mt-2 
                                    text-[1rem] sm:text-[1.8rem]"
                                    // style={{ border: "1px solid red" }}
                                />
                                <div
                                    className="container text-justify text-white scrollbar overflow-y-auto
                                    text-[0.5rem] 2xs:text-[0.76rem] sm:text-sm 
                                    mt-0 sm:mt-2 
                                    w-[14rem] 2xs:w-[16rem] sm:w-[25rem] 
                                    h-[3.7rem] 2xs:h-[4.5rem] sm:h-[6rem]"
                                    // style={{ border: "1px solid red" }}
                                >
                                    {d.content}
                                </div>
                                <div
                                    className="container bg-slate-50 
                                    text-[0.5rem] 2xs:text-[0.75rem] sm:text-base
                                    mt-1 sm:mt-4 
                                    w-[6rem] 2xs:w-[8rem] sm:w-[15rem] py-0 sm:py-1"
                                >
                                    {" "}
                                    {d.title}
                                </div>
                            </div>
                        )
                    })}

                    {/* <div className="carousel-item active px-sm-5 mb-4" data-bs-interval="2000">
                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center px-2 mx-2 px-md-3 mx-md-3 px-xl-5 mx-xl-5">
                            <div className="pb-4 pb-md-0 me-4">
                                <List.Item.Meta avatar={<Avatar src={data2[0].avatar} style={{ height: "200px", width: "200px" }} />} />
                            </div>

                            <div>
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                                    <div>
                                        <Title level={5} className="text-nowrap p-0 m-0">
                                            {data2[0].title}
                                        </Title>
                                        <div className="d-flex flex-row flex-wrap">
                                            <Text italic={true} className="text-nowrap me-2">
                                                recommends
                                            </Text>
                                            <Text strong={true} className="text-nowrap">
                                                {t("review.Title")}
                                            </Text>
                                        </div>
                                    </div>
                                    <Rate disabled defaultValue={5} className="d-flex flex-row flex-nowrap" />
                                </div>
                                <Divider className="pt-0 mt-2" />
                                <div className="d-flex flex-column justify-content-around">{data2[0].content}</div>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item px-sm-5 mb-4" data-bs-interval="3000">
                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center px-2 mx-2 px-md-3 mx-md-3 px-xl-5 mx-xl-5">
                            <div className="pb-4 pb-md-0 me-4">
                                <List.Item.Meta avatar={<Avatar src={data2[1].avatar} style={{ height: "200px", width: "200px" }} />} />
                            </div>

                            <div>
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                                    <div>
                                        <Title level={5} className="text-nowrap p-0 m-0">
                                            {data2[1].title}
                                        </Title>
                                        <div className="d-flex flex-row flex-wrap">
                                            <Text italic={true} className="text-nowrap me-2">
                                                recommends
                                            </Text>
                                            <Text strong={true} className="text-nowrap">
                                                {t("review.Title")}
                                            </Text>
                                        </div>
                                    </div>
                                    <Rate disabled defaultValue={5} className="d-flex flex-row flex-nowrap" />
                                </div>
                                <Divider className="pt-0 mt-2" />
                                <div className="d-flex flex-column justify-content-around">{data2[1].content}</div>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item px-sm-5 mb-4" data-bs-interval="2000">
                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center px-2 mx-2 px-md-3 mx-md-3 px-xl-5 mx-xl-5">
                            <div className="pb-4 pb-md-0 me-4">
                                <List.Item.Meta avatar={<Avatar src={data2[2].avatar} style={{ height: "200px", width: "200px" }} />} />
                            </div>

                            <div>
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                                    <div>
                                        <Title level={5} className="text-nowrap p-0 m-0">
                                            {data2[2].title}
                                        </Title>
                                        <div className="d-flex flex-row flex-wrap">
                                            <Text italic={true} className="text-nowrap me-2">
                                                recommends
                                            </Text>
                                            <Text strong={true} className="text-nowrap">
                                                {t("review.Title")}
                                            </Text>
                                        </div>
                                    </div>
                                    <Rate disabled defaultValue={5} className="d-flex flex-row flex-nowrap" />
                                </div>
                                <Divider className="pt-0 mt-2" />
                                <div className="d-flex flex-column justify-content-around">{data2[2].content}</div>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item px-sm-5 mb-4" data-bs-interval="3000">
                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center px-2 mx-2 px-md-3 mx-md-3 px-xl-5 mx-xl-5">
                            <div className="pb-4 pb-md-0 me-4">
                                <List.Item.Meta avatar={<Avatar src={data2[3].avatar} style={{ height: "200px", width: "200px" }} />} />
                            </div>

                            <div>
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                                    <div>
                                        <Title level={5} className="text-nowrap p-0 m-0">
                                            {data2[3].title}
                                        </Title>
                                        <div className="d-flex flex-row flex-wrap">
                                            <Text italic={true} className="text-nowrap me-2">
                                                recommends
                                            </Text>
                                            <Text strong={true} className="text-nowrap">
                                                {t("review.Title")}
                                            </Text>
                                        </div>
                                    </div>
                                    <Rate disabled defaultValue={5} className="d-flex flex-row flex-nowrap" />
                                </div>
                                <Divider className="pt-0 mt-2" />
                                <div className="d-flex flex-column justify-content-around">{data2[3].content}</div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleInterval"
                    data-bs-slide="next"
                    // onClick={(e) => console.log(e)}
                >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    )
}

export default Review
