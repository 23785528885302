import type { MapToken } from "antd/es/theme/interface"
import type { SeedToken } from "antd/es/theme/internal"
import { useLoaderData, useNavigate } from "react-router-dom"

import { Button, Image } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

import { getTreatmentEntry, importAll } from "./usefulScripts"
import ErrorPage from "./Error-page"

export interface TreatmentEntryType {
    imageUrl: string
    Title1: string
    Intro: string
    Title2: string
    Reasons: string[]
    Title3: string
    Consequences: string[]
}
const images = importAll(require.context("./img", false, /Treatment_.*/)) as { [key: string]: string }

export async function loader({ params }: { params: any }) {
    console.log("treatmentId:", params.treatmentId)
    const entry = await getTreatmentEntry(params.treatmentId)
    console.log("entry", entry)

    return entry
}
function TreatmentEntry(
    {
        // currentTheme,
        // }: {
        // currentTheme: { algorithm: (token: SeedToken) => MapToken }
    }
) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const res = useLoaderData() as TreatmentEntryType
    console.log("data res:", res)
    if (res === null) return <ErrorPage />
    else {
        const { imageUrl, Title1, Intro, Title2, Reasons, Title3, Consequences } = res
        console.log("data:", { imageUrl, Title1, Intro, Title2, Reasons, Title3, Consequences })
        console.log("img", images["Treatment_1.png"])

        return (
            <div className="space-y-5 lg:space-y-8 pb-12">
                <div className="relative container lg:mx-auto text-center py-5 lg:py-16" style={{ border: "0px solid red" }}>
                    {/* <div className="flex flex-row align-items-center" style={{ border: "1px solid red" }}>
                        <a className="flex flex-row gap-3 items-center" href="/treatments" style={{ border: "1px solid red" }}>
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                color="black"
                                height="30"
                                width="30"
                                xmlns="http://www.w3.org/2000/svg"
                                // style={{ color: "black" }}
                            >
                                <path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z"></path>
                            </svg>
                            Back to Treatments
                        </a>
                    </div> */}
                    <Button icon={<LeftOutlined />} className="flex justify-self-start" onClick={() => navigate("/treatments")}>
                        {t(`nav.Treatment`)}
                    </Button>
                    <div
                        className="block lg:flex lg:items-start lg:justify-content-between gap-8 mt-3 w-[100%] rounded-lg shadow-md border p-5"
                        style={{ border: "0px solid red" }}
                    >
                        {/* <img
                            alt="treatment"
                            srcSet="/_next/image?url=%2Fimages%2Ftreatments%2Ftittarman_back_soreness.png&amp;w=640&amp;q=75 1x, /_next/image?url=%2Fimages%2Ftreatments%2Ftittarman_back_soreness.png&amp;w=1080&amp;q=75 2x"
                            src="/_next/image?url=%2Fimages%2Ftreatments%2Ftittarman_back_soreness.png&amp;w=1080&amp;q=75"
                            width="500"
                            height="500"
                            decoding="async"
                            data-nimg="1"
                            style={{ color: "transparent" }}
                        /> */}
                        <div
                            className="container self-center w-full md:w-[70%] lg:w-2/4 xl:w-2/4
                        "
                            // style={{ border: "1px solid red" }}
                        >
                            <Image
                                src={imageUrl}
                                className="animate-fade"
                                // w-[80vw] md:w-[50vw] lg:w-[50vw] xl:w-[35vw]  rounded-xl border
                                preview={false}
                                // style={{ border: "0px solid red" }}
                            />
                        </div>
                        <div
                            className="w-full lg:w-2/4 xl:w-2/4 space-y-10 text-left
                        border-t lg:border-t-0 border-s-0 lg:border-s 
                        pt-5 lg:pt-0 ps-0 lg:ps-5
                        "
                            // style={{ border: "0px solid red" }}
                        >
                            <div className="space-y-3 lg:space-y-8">
                                <div className="text-xl lg:text-2xl font-extrabold">{t(Title1)}</div>
                                <div className="text-justify mt-3">{t(Intro)}</div>
                                <div className="space-y-8">
                                    <div className="space-y-3">
                                        <div className="font-bold">{t(Title2)} </div>
                                        <section className="list-decimal space-y-2">
                                            {Reasons.map((reason, i) => {
                                                return t(reason) ? (
                                                    <li key={`reason_${i}`} className="text-normal text-justify">
                                                        {t(reason)}
                                                    </li>
                                                ) : undefined
                                            })}
                                        </section>
                                    </div>
                                    <div className="space-y-3">
                                        <div className="font-bold">{t(Title3)}</div>
                                        <section className="list-decimal space-y-2">
                                            {Consequences.map((consequent, i) => {
                                                return t(consequent) ? (
                                                    <li key={`consequent_${i}`} className="text-normal text-justify">
                                                        {t(consequent)}
                                                    </li>
                                                ) : undefined
                                            })}
                                        </section>
                                    </div>
                                </div>
                                {/* <div className="table w-[150px] border-b-black border-b"></div> */}
                                {/* <div className="text-[1em] flex flex-col">
                                    WhatsApp or Call us at : <span className="font-extrabold text-xl">60186661010</span>
                                </div> */}
                            </div>
                            {/* <button className="bg-ttm-yellow-900 hover:bg-ttm-yellow-800 font-bold py-2 px-8 text-lg tracking-wider text-black items-center gap-4 rounded-sm">
                                Make Appointment
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TreatmentEntry
