import type { MapToken } from "antd/es/theme/interface"
import type { SeedToken } from "antd/es/theme/internal"
import { useTranslation } from "react-i18next"
import { Divider, theme, Typography, Button, Image } from "antd"
import { importAll } from "./usefulScripts"
import footer_img from "./img/banner.jpeg"
import { FacebookFilled, YoutubeFilled, EnvironmentFilled, WhatsAppOutlined } from "@ant-design/icons"
import Faq from "./Faq"

import { GoogleMapIcon, WazeIcon } from "./CustomIcon"

const { Title, Text } = Typography

const images = importAll(require.context("./img", false, /(qrcode|Banner|Contact|Footer).*/)) as { [key: string]: string }
function ContactUs(
    {
        // currentTheme,
        // }: {
        // currentTheme: { algorithm: (token: SeedToken) => MapToken }
    }
) {
    const { t, i18n } = useTranslation()
    const { token } = theme.useToken()

    return (
        <div className="pb-24" style={{ animation: "fadeIn 0.5s ease-in-out" }}>
            <div
                className="h-[5rem] 2xs:h-[7.5rem] sm:h-[20rem] xl:h-[25rem] mb-20 relative ps-[10%] sm:ps-[15%]  pt-[3%] sm:pt-[5%]"
                style={{ backgroundSize: "cover", backgroundImage: `url(${images["Contact_Banner.png"]})` }}
            >
                <div className="h-100 w-[55%] md:w-[55%]" style={{ border: "0px solid red" }}>
                    <div className="tracking-wider font-light text-xs 2xs:text-base sm:text-5xl">{t("contact.Banner.Title")}</div>
                    <div className="border-b w-4 sm:w-10 border-black sm:border-1 my-1 sm:my-3"></div>
                    <div className="text-[0.3rem] 2xs:text-[0.5rem] sm:text-sm italic font-serif font-semibold mb-1 sm:mb-5">
                        {t("contact.Banner.Shop")}
                    </div>
                    <div
                        className="text-[0.5rem] 2xs:text-[0.60rem] sm:text-2xl font-serif italic font-medium"
                        style={{ fontFamily: "Great-Vibes-Regular1" }}
                    >
                        No:22-G Jalan Eco Ardence CU12/36C,
                        <br /> Eco Ardence Crest, 40170 Shah Alam, Selangor, Malaysia
                    </div>
                </div>
            </div>
            <div className="relative lg:mx-auto text-center" style={{ border: "0px solid #73AD21" }}>
                <div className="text-left" style={{ border: "0px solid #73AD21" }}>
                    <Divider className="my-5 text-xl lg:text-2xl font-extrabold">{t("contact.Title")}</Divider>
                    {/* <div className="grid grid-cols-12 px-0 lg:px-16 xl:px-32" style={{ border: "0px solid yellow" }}>
                        <div
                            className="text-xl lg:text-2xl col-start-2 col-span-10 ps-2 lg:ps-0
                                   xxs:mb-[0rem] md:mb-[2rem] lg:mb-[0rem] font-extrabold

                                  "
                        >
                            {t("contact.Title")}
                        </div>
                    </div> */}
                    <div className="space-y-8 lg:space-y-10 py-10 lg:py-20 px-0 lg:px-16 xl:px-32" style={{ border: "0px solid purple" }}>
                        <div className="grid grid-cols-12 items-start gap-12" style={{ border: "0px solid green" }}>
                            <div
                                className="text-left col-span-7 2xs:col-span-8 lg:col-span-4 col-start-2 2xs:col-start-2 md:col-start-3 lg:col-start-2 grid order-[1] lg:order-[0]"
                                style={{ border: "0px solid green" }}
                            >
                                <div className="mb-4">
                                    <div className="text-xl xl:text-2xl font-bold">{t("contact.Consultation")}</div>
                                    <div className="md:text-base xl:text-lg mt-3">{t("contact.Consultation_Descript")}</div>
                                </div>
                                <div>
                                    <div className="text-xl lg:text-2xl font-bold">{t("contact.Appointment")}</div>
                                    <div className="md:text-base xl:text-lg mt-3">
                                        {t("contact.Appointment_Descript1")}
                                        <br />
                                        <br />
                                        {t("contact.Appointment_Descript2")}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full col-span-7 2xs:col-span-8 md:col-span-7 lg:col-span-5 col-start-2 2xs:col-start-2 md:col-start-3 lg:col-start-7 grid order-[0] lg:order-[1]">
                                <Image
                                    // alt="about us"
                                    src={images["Contact_Banner_1.png"]}
                                    decoding="async"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="space-y-8 lg:space-y-10 bg-slate-50 py-10 lg:py-20 px-0 lg:px-16 xl:px-32" style={{ border: "0px solid red" }}>
                        <div className="grid grid-cols-12 gap-12 items-start" style={{ border: "0px solid red" }}>
                            <div
                                className="text-left col-span-7 2xs:col-span-8  lg:col-span-4 col-start-2 2xs:col-start-2 md:col-start-3 lg:col-start-7 grid order-[1] lg:order-[1]"
                                style={{ border: "0px solid green" }}
                            >
                                <div className="mb-4">
                                    <div className="text-xl lg:text-2xl font-bold">{t("contact.Diagnosis")}</div>
                                    <div className="md:text-base xl:text-lg mt-3">{t("contact.Diagnosis_Descript")}</div>
                                </div>
                                <div>
                                    <div className="text-xl lg:text-2xl font-bold">{t("contact.Explanation")}</div>
                                    <div className="md:text-base xl:text-lg mt-3">{t("contact.Explanation_Descript")}</div>
                                </div>
                            </div>
                            <div className="w-full col-span-7 2xs:col-span-8 md:col-span-7 lg:col-span-5 col-start-2 2xs:col-start-2 md:col-start-3 lg:col-start-2 grid order-[0] lg:order-[0]">
                                <Image
                                    src={images["Contact_Banner_2.png"]}
                                    decoding="async"
                                    // style={{ border: "1px solid green" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="space-y-8 lg:space-y-10 py-10 lg:py-20 px-0 lg:px-16 xl:px-32" style={{ border: "0px solid red" }}>
                        <div className="grid grid-cols-12 lg:space-x-5 gap-12 items-start" style={{ border: "0px solid red" }}>
                            <div
                                className="text-left col-span-7 2xs:col-span-8 lg:col-span-4 col-start-2 2xs:col-start-2 md:col-start-3 lg:col-start-2 grid order-[1] lg:order-[0]"
                                style={{ border: "0px solid green" }}
                            >
                                <div className="mb-4">
                                    <div className="text-xl lg:text-2xl font-bold">{t("contact.Cost")}</div>
                                    <div className="md:text-base xl:text-lg mt-3">{t("contact.Cost_Descript")}</div>
                                </div>
                                <div className="mb-4">
                                    <div className="text-xl lg:text-2xl font-bold">{t("contact.Treatment")}</div>
                                    <div className="md:text-base xl:text-lg mt-3">{t("contact.Treatment_Descript")}</div>
                                </div>
                                <div>
                                    <div className="text-xl lg:text-2xl font-bold">{t("contact.Follow_up")}</div>
                                    <div className="md:text-base xl:text-lg mt-3">{t("contact.Follow_up_Descript")}</div>
                                </div>
                            </div>
                            <div className="w-full col-span-7 2xs:col-span-8 md:col-span-7 lg:col-span-5 col-start-2 2xs:col-start-2 md:col-start-3 lg:col-start-7 grid order-[0] lg:order-[1]">
                                <Image
                                    // alt="about us"
                                    src={images["Contact_Banner_3.png"]}
                                    // onLoad={() => <>Loading</>}
                                    decoding="async"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Divider className="my-12 text-xl lg:text-2xl font-extrabold">{t("faq.Title")}</Divider>
                <div className="w-[90vw] md:w-[70vw] lg:w-[50vw] mx-auto">
                    <Faq />
                </div>
                <Divider className="my-12 text-xl lg:text-2xl font-extrabold">{t("footer.Address")}</Divider>

                <div className="d-flex flex-column flex-lg-column justify-content-start align-items-start ps-[3rem] lg:ps-[8rem] px-0 md:px-32 xl:px-64">
                    <div className="d-flex flex-column align-items-start pe-3 pb-5 w-[100%]">
                        <div
                            className="d-flex flex-column flex-lg-row justify-content-start align-items-start w-[100%] mt-5 mb-12 lg:mb-24"
                            style={{ border: "0px solid red" }}
                        >
                            <iframe
                                className="h-[15rem] w-[75vw] md:h-[20rem] md:w-[50vw] lg:h-[25rem] lg:w-[35rem] me-[0] lg:me-[2rem] mb-[2rem] lg:mb-[0]"
                                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Efeng%20Spine+(Efeng%20Spine%20Healing%20Center)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            />
                            <div className="d-flex flex-column align-items-start ms-2">
                                <div className="d-flex flex-row justify-content-around align-items-start mb-5">
                                    <EnvironmentFilled className="pe-4 pt-1" />
                                    <div className="text-start" style={{ width: 250 }}>
                                        <a
                                            href="https://goo.gl/maps/rn85HSadNXTyCwiT6"
                                            target="_blank"
                                            className="text-wrap"
                                            style={{ textDecoration: "none", color: "black", letterSpacing: 0.35 }}
                                        >
                                            NO:22-G JALAN ECO ARDENCE CU12/36C, ECO ARDENCE CREST, 40170 SHAH ALAM, SELANGOR, MALAYSIA
                                        </a>
                                    </div>
                                </div>

                                <div className="flex flex-row gap-x-5 ps-5">
                                    <Button
                                        icon={<WazeIcon style={{ fontSize: "1.2rem" }} />}
                                        type="default"
                                        shape="circle"
                                        size="large"
                                        onClick={() =>
                                            window.open(
                                                "https://ul.waze.com/ul?place=ChIJYQLntzlTzDERtWW5PyAMcp8&ll=3.08817540%2C101.47716560&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location",
                                                "_blank"
                                            )
                                        }
                                    />

                                    <Button
                                        icon={<GoogleMapIcon style={{ fontSize: "1.5rem" }} />}
                                        type="default"
                                        shape="circle"
                                        size="large"
                                        onClick={() => window.open("https://goo.gl/maps/rn85HSadNXTyCwiT6", "_blank")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-flex flex-column flex-lg-row justify-content-start align-items-start w-[100%]"
                            style={{ border: "0px solid red" }}
                        >
                            <iframe
                                className="h-[15rem] w-[75vw] md:h-[20rem] md:w-[50vw] lg:h-[25rem] lg:w-[35rem] me-[0] lg:me-[2rem] mb-[2rem] lg:mb-[0]"
                                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Lot%202892%20Jalan%20C/44%20Kawasan%20Perindustrian%20Padang%20Tembak%20II%2016100%20Kota%20Bharu%20Kelantan+(efeng%20spine%20)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            />
                            <div className="d-flex flex-column align-items-start ms-2">
                                <div className="d-flex flex-row justify-content-around align-items-start mb-5">
                                    <EnvironmentFilled className="pe-4 pt-1" />
                                    <div className="text-start" style={{ width: 250 }}>
                                        {" "}
                                        <a
                                            href="https://www.google.com/maps/place/Jln+C%2F44,+Kawasan+Perindustrian+Mara,+16100+Pengkalan+Chepa,+Kelantan/@6.1439241,102.2913691,17z/data=!3m1!4b1!4m6!3m5!1s0x31b6b1ae9968786d:0x4a8431a859dd82be!8m2!3d6.1439241!4d102.293944!16s%2Fg%2F11cnqg2bp2?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"
                                            target="_blank"
                                            className="text-wrap"
                                            style={{ textDecoration: "none", color: "black", letterSpacing: 0.35 }}
                                        >
                                            Lot 2892 Jalan C/44 Kawasan Perindustrian Padang Tembak II 16100 Kota Bharu Kelantan
                                        </a>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-x-5 ps-5">
                                    <Button
                                        icon={<WazeIcon style={{ fontSize: "1.2rem" }} />}
                                        type="default"
                                        shape="circle"
                                        size="large"
                                        onClick={() =>
                                            window.open(
                                                "https://www.waze.com/en-GB/live-map/directions/my/kelantan/pengkalan-chepa/lh-lai-huat-sdn-bhd?navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location&to=place.ChIJwY9MimmxtjERTmGQbneTB8k",
                                                "_blank"
                                            )
                                        }
                                    />
                                    <Button
                                        icon={<GoogleMapIcon style={{ fontSize: "1.5rem" }} />}
                                        type="default"
                                        shape="circle"
                                        size="large"
                                        onClick={() =>
                                            window.open(
                                                "https://www.google.com/maps/place/Jln+C%2F44,+Kawasan+Perindustrian+Mara,+16100+Pengkalan+Chepa,+Kelantan/@6.1439241,102.2913691,17z/data=!3m1!4b1!4m6!3m5!1s0x31b6b1ae9968786d:0x4a8431a859dd82be!8m2!3d6.1439241!4d102.293944!16s%2Fg%2F11cnqg2bp2?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D",
                                                "_blank"
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <img src={footer_img} width="300" className="footer_img" /> */}
                </div>
            </div>
        </div>
    )
}

export default ContactUs
