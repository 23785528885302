import React from "react"

const images = importAll(require.context("./img", false, /Treatment_.*/)) as { [key: string]: string }

type ModuleId = string | number
interface RequireContext {
    keys(): string[]
    (id: string): any
    <T>(id: string): T
    resolve(id: string): string
    /** The module id of the context module. This may be useful for module.hot.accept. */
    id: ModuleId
}

export const TreatmentDataKeys: { [key: string]: string[] } = {
    Orthopaedics: ["Scoliosis", "Hunchback", "Kyphosis", "Pelvic-Readjustment", "Feet-with-OX-deformity"],
    "Orthopaedics and Joint": [
        "Frozen-Shoulder",
        "Imbalance-Shoulder",
        "Trigger-Finger",
        "Bone-Spur",
        "Gelatinous-Knee",
        "Sciatic-nerve",
        "Lumbar-vertebrae-from-L1-to-L5",
        "Thoracic-vertebrae-T1-T12",
        "Cervical-vertebrae-C1-C7",
    ],
    neurology: ["Spinal-Nervous-System", "Dowager-Hump", "Ear-fluid-imbalance", "Paralysis", "Headache", "Migraine"],
}

// const treatmentData: { [key: string]: { [key: string]: string | string[] } } = {
//     "Spinal-Nervous-System": {
//         imageUrl: images["Treatment_Spinal-Nervous-System.png"],
//         Title1: "treatment.TreatmentData.Spinal-Nervous-System.Title1",
//         Intro: "treatment.TreatmentData.Spinal-Nervous-System.Intro",
//         Title2: "treatment.TreatmentData.Spinal-Nervous-System.Title2",
//         Reasons: [
//             "treatment.TreatmentData.Spinal-Nervous-System.Reasons.1",
//             "treatment.TreatmentData.Spinal-Nervous-System.Reasons.2",
//             "treatment.TreatmentData.Spinal-Nervous-System.Reasons.3",
//         ],
//         Title3: "treatment.TreatmentData.Spinal-Nervous-System.Title3",
//         Consequences: [
//             "treatment.TreatmentData.Spinal-Nervous-System.Consequences.1",
//             "treatment.TreatmentData.Spinal-Nervous-System.Consequences.2",
//         ],
//     },
//     Scoliosis: {
//         imageUrl: images["Treatment_Scoliosis.png"],
//         Title1: "脊椎神经系统",
//         Intro: "脊椎神经系统是我们身体的神经系统的一个重要部分，它包括脊髓和从脊髓发出的神经。简单来说，脊椎神经系统负责传递大脑与身体其他部分之间的信息。",
//         Title2: "脊椎神经系统出现问题的原因有很多，以下是一些常见的因素：",
//         Reasons: [
//             "神经病变：脊椎压迫或其他代谢性疾病可能导致周围神经病变，影响脊椎神经系统的功能。",
//             "创伤或事故：例如车祸、跌倒或运动损伤可能导致脊髓损伤或脊神经受压。",
//             "椎间盘突出：椎间盘中的软物质突出，压迫脊神经根，可能导致疼痛、麻木或肌肉无力。",
//         ],
//         Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
//         Consequences: [
//             "持续或加剧的疼痛：如果神经受到持续压迫或损伤，可能会导致长期、严重的疼痛，这种疼痛可能难以通过普通的止痛药缓解。",
//             "运动功能障碍：如果脊髓或脊神经受到压迫，可能会导致肌肉无力、协调失调或瘫痪。这可能影响到日常活动，如走路、拿东西等。",
//         ],
//     },
//     Hunchback: {
//         imageUrl: images["Treatment_Hunchback.png"],
//         Title1: "脊椎神经系统",
//         Intro: "脊椎神经系统是我们身体的神经系统的一个重要部分，它包括脊髓和从脊髓发出的神经。简单来说，脊椎神经系统负责传递大脑与身体其他部分之间的信息。",
//         Title2: "脊椎神经系统出现问题的原因有很多，以下是一些常见的因素：",
//         Reasons: [
//             "神经病变：脊椎压迫或其他代谢性疾病可能导致周围神经病变，影响脊椎神经系统的功能。",
//             "创伤或事故：例如车祸、跌倒或运动损伤可能导致脊髓损伤或脊神经受压。",
//             "椎间盘突出：椎间盘中的软物质突出，压迫脊神经根，可能导致疼痛、麻木或肌肉无力。",
//         ],
//         Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
//         Consequences: [
//             "持续或加剧的疼痛：如果神经受到持续压迫或损伤，可能会导致长期、严重的疼痛，这种疼痛可能难以通过普通的止痛药缓解。",
//             "运动功能障碍：如果脊髓或脊神经受到压迫，可能会导致肌肉无力、协调失调或瘫痪。这可能影响到日常活动，如走路、拿东西等。",
//         ],
//     },
//     "Dowager-Hump": {
//         imageUrl: images["Treatment_Dowager-Hump.png"],
//         Title1: "脊椎神经系统",
//         Intro: "脊椎神经系统是我们身体的神经系统的一个重要部分，它包括脊髓和从脊髓发出的神经。简单来说，脊椎神经系统负责传递大脑与身体其他部分之间的信息。",
//         Title2: "脊椎神经系统出现问题的原因有很多，以下是一些常见的因素：",
//         Reasons: [
//             "神经病变：脊椎压迫或其他代谢性疾病可能导致周围神经病变，影响脊椎神经系统的功能。",
//             "创伤或事故：例如车祸、跌倒或运动损伤可能导致脊髓损伤或脊神经受压。",
//             "椎间盘突出：椎间盘中的软物质突出，压迫脊神经根，可能导致疼痛、麻木或肌肉无力。",
//         ],
//         Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
//         Consequences: [
//             "持续或加剧的疼痛：如果神经受到持续压迫或损伤，可能会导致长期、严重的疼痛，这种疼痛可能难以通过普通的止痛药缓解。",
//             "运动功能障碍：如果脊髓或脊神经受到压迫，可能会导致肌肉无力、协调失调或瘫痪。这可能影响到日常活动，如走路、拿东西等。",
//         ],
//     },
//     "Frozen-Shoulder": {
//         imageUrl: images["Treatment_Frozen-Shoulder.png"],
//         Title1: "脊椎神经系统",
//         Intro: "脊椎神经系统是我们身体的神经系统的一个重要部分，它包括脊髓和从脊髓发出的神经。简单来说，脊椎神经系统负责传递大脑与身体其他部分之间的信息。",
//         Title2: "脊椎神经系统出现问题的原因有很多，以下是一些常见的因素：",
//         Reasons: [
//             "神经病变：脊椎压迫或其他代谢性疾病可能导致周围神经病变，影响脊椎神经系统的功能。",
//             "创伤或事故：例如车祸、跌倒或运动损伤可能导致脊髓损伤或脊神经受压。",
//             "椎间盘突出：椎间盘中的软物质突出，压迫脊神经根，可能导致疼痛、麻木或肌肉无力。",
//         ],
//         Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
//         Consequences: [
//             "持续或加剧的疼痛：如果神经受到持续压迫或损伤，可能会导致长期、严重的疼痛，这种疼痛可能难以通过普通的止痛药缓解。",
//             "运动功能障碍：如果脊髓或脊神经受到压迫，可能会导致肌肉无力、协调失调或瘫痪。这可能影响到日常活动，如走路、拿东西等。",
//         ],
//     },
//     Kyphosis: {
//         imageUrl: images["Treatment_Kyphosis.png"],
//         Title1: "脊椎神经系统",
//         Intro: "脊椎神经系统是我们身体的神经系统的一个重要部分，它包括脊髓和从脊髓发出的神经。简单来说，脊椎神经系统负责传递大脑与身体其他部分之间的信息。",
//         Title2: "脊椎神经系统出现问题的原因有很多，以下是一些常见的因素：",
//         Reasons: [
//             "神经病变：脊椎压迫或其他代谢性疾病可能导致周围神经病变，影响脊椎神经系统的功能。",
//             "创伤或事故：例如车祸、跌倒或运动损伤可能导致脊髓损伤或脊神经受压。",
//             "椎间盘突出：椎间盘中的软物质突出，压迫脊神经根，可能导致疼痛、麻木或肌肉无力。",
//         ],
//         Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
//         Consequences: [
//             "持续或加剧的疼痛：如果神经受到持续压迫或损伤，可能会导致长期、严重的疼痛，这种疼痛可能难以通过普通的止痛药缓解。",
//             "运动功能障碍：如果脊髓或脊神经受到压迫，可能会导致肌肉无力、协调失调或瘫痪。这可能影响到日常活动，如走路、拿东西等。",
//         ],
//     },
//     "Imbalance-Shoulder": {
//         imageUrl: images["Treatment_Imbalance-Shoulder.png"],
//         Title1: "脊椎神经系统",
//         Intro: "脊椎神经系统是我们身体的神经系统的一个重要部分，它包括脊髓和从脊髓发出的神经。简单来说，脊椎神经系统负责传递大脑与身体其他部分之间的信息。",
//         Title2: "脊椎神经系统出现问题的原因有很多，以下是一些常见的因素：",
//         Reasons: [
//             "神经病变：脊椎压迫或其他代谢性疾病可能导致周围神经病变，影响脊椎神经系统的功能。",
//             "创伤或事故：例如车祸、跌倒或运动损伤可能导致脊髓损伤或脊神经受压。",
//             "椎间盘突出：椎间盘中的软物质突出，压迫脊神经根，可能导致疼痛、麻木或肌肉无力。",
//         ],
//         Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
//         Consequences: [
//             "持续或加剧的疼痛：如果神经受到持续压迫或损伤，可能会导致长期、严重的疼痛，这种疼痛可能难以通过普通的止痛药缓解。",
//             "运动功能障碍：如果脊髓或脊神经受到压迫，可能会导致肌肉无力、协调失调或瘫痪。这可能影响到日常活动，如走路、拿东西等。",
//         ],
//     },
//     "Trigger-Finger": {
//         imageUrl: images["Treatment_Trigger-Finger.png"],
//         Title1: "脊椎神经系统",
//         Intro: "脊椎神经系统是我们身体的神经系统的一个重要部分，它包括脊髓和从脊髓发出的神经。简单来说，脊椎神经系统负责传递大脑与身体其他部分之间的信息。",
//         Title2: "脊椎神经系统出现问题的原因有很多，以下是一些常见的因素：",
//         Reasons: [
//             "神经病变：脊椎压迫或其他代谢性疾病可能导致周围神经病变，影响脊椎神经系统的功能。",
//             "创伤或事故：例如车祸、跌倒或运动损伤可能导致脊髓损伤或脊神经受压。",
//             "椎间盘突出：椎间盘中的软物质突出，压迫脊神经根，可能导致疼痛、麻木或肌肉无力。",
//         ],
//         Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
//         Consequences: [
//             "持续或加剧的疼痛：如果神经受到持续压迫或损伤，可能会导致长期、严重的疼痛，这种疼痛可能难以通过普通的止痛药缓解。",
//             "运动功能障碍：如果脊髓或脊神经受到压迫，可能会导致肌肉无力、协调失调或瘫痪。这可能影响到日常活动，如走路、拿东西等。",
//         ],
//     },
//     "Bone-Spur": {
//         imageUrl: images["Treatment_Bone-Spur.png"],
//         Title1: "脊椎神经系统",
//         Intro: "脊椎神经系统是我们身体的神经系统的一个重要部分，它包括脊髓和从脊髓发出的神经。简单来说，脊椎神经系统负责传递大脑与身体其他部分之间的信息。",
//         Title2: "脊椎神经系统出现问题的原因有很多，以下是一些常见的因素：",
//         Reasons: [
//             "神经病变：脊椎压迫或其他代谢性疾病可能导致周围神经病变，影响脊椎神经系统的功能。",
//             "创伤或事故：例如车祸、跌倒或运动损伤可能导致脊髓损伤或脊神经受压。",
//             "椎间盘突出：椎间盘中的软物质突出，压迫脊神经根，可能导致疼痛、麻木或肌肉无力。",
//         ],
//         Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
//         Consequences: [
//             "持续或加剧的疼痛：如果神经受到持续压迫或损伤，可能会导致长期、严重的疼痛，这种疼痛可能难以通过普通的止痛药缓解。",
//             "运动功能障碍：如果脊髓或脊神经受到压迫，可能会导致肌肉无力、协调失调或瘫痪。这可能影响到日常活动，如走路、拿东西等。",
//         ],
//     },
//     "Gelatinous-Knee": {
//         imageUrl: images["Treatment_Gelatinous-Knee.png"],
//         Title1: "脊椎神经系统",
//         Intro: "脊椎神经系统是我们身体的神经系统的一个重要部分，它包括脊髓和从脊髓发出的神经。简单来说，脊椎神经系统负责传递大脑与身体其他部分之间的信息。",
//         Title2: "脊椎神经系统出现问题的原因有很多，以下是一些常见的因素：",
//         Reasons: [
//             "神经病变：脊椎压迫或其他代谢性疾病可能导致周围神经病变，影响脊椎神经系统的功能。",
//             "创伤或事故：例如车祸、跌倒或运动损伤可能导致脊髓损伤或脊神经受压。",
//             "椎间盘突出：椎间盘中的软物质突出，压迫脊神经根，可能导致疼痛、麻木或肌肉无力。",
//         ],
//         Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
//         Consequences: [
//             "持续或加剧的疼痛：如果神经受到持续压迫或损伤，可能会导致长期、严重的疼痛，这种疼痛可能难以通过普通的止痛药缓解。",
//             "运动功能障碍：如果脊髓或脊神经受到压迫，可能会导致肌肉无力、协调失调或瘫痪。这可能影响到日常活动，如走路、拿东西等。",
//         ],
//     },
// }
function importAll(r: RequireContext) {
    // Import all 'case' images
    let images: { [key: string]: string } = {}
    r.keys().map((item) => {
        images[item.replace("./", "")] = r(item)
        return null
    })
    return images
}
function useInterval(callback: () => void, delay: number) {
    const savedCallback = React.useRef<any>()

    React.useEffect(() => {
        savedCallback.current = callback
    })

    React.useEffect(() => {
        function tick() {
            savedCallback.current()
        }

        let id = setInterval(tick, delay)
        return () => clearInterval(id)
    }, [delay])
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const getBreakPoint = (screens: { [key: string]: boolean }) => {
    if (screens.xs) return "xs"
    else {
        if (screens.xxl) return "xxl"
        else if (screens.xl) return "xl"
        else if (screens.lg) return "lg"
        else if (screens.md) return "md"
        else return "sm"
    }
}

const getTreatmentEntry = (id: string) => {
    if (images[`Treatment_${id}.png`])
        return {
            imageUrl: images[`Treatment_${id}.png`],
            Title1: `treatment.TreatmentData.${id}.Title1`,
            Intro: `treatment.TreatmentData.${id}.Intro`,
            Title2: `treatment.TreatmentData.${id}.Title2`,
            Reasons: [
                `treatment.TreatmentData.${id}.Reasons.1`,
                `treatment.TreatmentData.${id}.Reasons.2`,
                `treatment.TreatmentData.${id}.Reasons.3`,
                `treatment.TreatmentData.${id}.Reasons.4`,
            ],
            Title3: `treatment.TreatmentData.${id}.Title3`,
            Consequences: [
                `treatment.TreatmentData.${id}.Consequences.1`,
                `treatment.TreatmentData.${id}.Consequences.2`,
                `treatment.TreatmentData.${id}.Consequences.3`,
                `treatment.TreatmentData.${id}.Consequences.4`,
            ],
        }
    else return null
}

function getRndInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min
}
export { importAll, useInterval, capitalizeFirstLetter, getBreakPoint, getTreatmentEntry, getRndInteger }
