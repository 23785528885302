import { useRouteError } from "react-router-dom"

export default function ErrorPage() {
    const error = useRouteError() as any
    console.error(error)
    // justifySelf: "center", alignSelf: "center",
    return (
        <div id="error-page" className="d-flex flex-row justify-content-around" style={{ height: "100vh" }}>
            <div className="d-flex flex-column align-items-center m-auto" style={{ width: "500px", height: "500px" }}>
                <h1 className="mb-3 text-danger">Oops!</h1>
                <p className="mb-3 text-danger">Sorry, an unexpected error has occurred.</p>
                <p className="mb-3 text-danger">
                    <i>{error ? error["statusText"] || error["message"] : null}</i>
                </p>
            </div>
        </div>
    )
}
