import React from "react"
import { theme, ConfigProvider } from "antd"
import frFR from "antd/locale/fr_FR"
import type { MapToken, SeedToken } from "antd/lib/theme/interface"
import Root from "./Root"
import { useTranslation } from "react-i18next"
import { useOutletContext } from "react-router-dom"

type ContextType = { lang: string; setLang: React.Dispatch<React.SetStateAction<string>> }

function App() {
    const { t, i18n } = useTranslation()
    //i18n.language
    const [lang, setLang] = React.useState<string>("zh")
    // const [myTheme, setMyTheme] = React.useState<string>("light")

    const browserThemeChange = (event: MediaQueryListEvent) => {
        if (event.matches) {
            return { algorithm: theme.darkAlgorithm }
        }
        return { algorithm: theme.defaultAlgorithm }
    }

    const [currentTheme, setCurrentTheme] = React.useState<{ algorithm: (token: SeedToken) => MapToken }>(() => {
        // If the browser has the property
        if (window.matchMedia) {
            // Watch the theme changes
            window.matchMedia("(prefers-color-scheme: dark)").addEventListener<"change">("change", browserThemeChange)

            // if (window.matchMedia("(prefers-color-scheme: light)").matches) {
            //     return { algorithm: theme.defaultAlgorithm }
            // } else {
            //     // return { algorithm: theme.defaultAlgorithm }
            //     return { algorithm: theme.darkAlgorithm }
            // }
        }
        return { algorithm: theme.defaultAlgorithm }
    })

    React.useEffect(() => {
        // configCSSManually()
    }, [currentTheme])
    React.useEffect(() => {
        i18n.changeLanguage(lang)
    }, [lang])
    const configCSSManually = () => {
        // Customize the body theme
        let dropdownLoginMenu = document.querySelector(".dropdown-login-menu")
        if (currentTheme.algorithm === theme.defaultAlgorithm) {
            document.body.style.backgroundColor = "white"
            if (dropdownLoginMenu) {
                dropdownLoginMenu.classList.remove("black")
            }
        } else {
            document.body.style.backgroundColor = "black"
            if (dropdownLoginMenu) {
                dropdownLoginMenu.classList.add("black")
            }
        }
    }

    const changeTheme = (checked: boolean) => {
        setCurrentTheme(() => {
            // if (checked) {
            //     return { algorithm: theme.darkAlgorithm }
            // }
            return { algorithm: theme.defaultAlgorithm }
        })
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#0891B2",
                    // colorPrimaryHover: ""
                },
                components: {
                    // Menu: {
                    //     colorPrimary: "#0891B2",
                    //     algorithm: true, // Enable algorithm
                    // },
                    Button: {
                        colorPrimary: "#0891B2",
                        colorLink: "#0891B2",
                        colorLinkHover: "#06b6d4",
                        // algorithm: true, // Enable algorithm
                    },
                    // Switch: {
                    //     handleSize: 30,
                    // },
                    Carousel: {
                        arrowSize: 25,
                        arrowOffset: 20,
                        dotOffset: 30,
                    },
                },
                ...currentTheme,
            }}
            // locale={frFR}
        >
            <Root currentTheme={currentTheme} changeTheme={changeTheme} configCSSManually={configCSSManually} lang={lang} setLang={setLang} />
        </ConfigProvider>
    )
}

export function useLang() {
    return useOutletContext<ContextType>()
}

export default App
