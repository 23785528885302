import type { MapToken } from "antd/es/theme/interface"
import type { SeedToken } from "antd/es/theme/internal"
import React from "react"
import { Carousel, Divider, Grid, Image, type GetProps } from "antd"
import Card from "./Card"
import Review from "./Review"
import { getBreakPoint, importAll } from "./usefulScripts"
import { useTranslation } from "react-i18next"

const images = importAll(require.context("./img", false, /(Experience|Contact).*/)) as { [key: string]: string }

const { useBreakpoint } = Grid

const contentStyle: React.CSSProperties = {
    margin: 0,
    height: "460px",

    // color: "black",
    // lineHeight: "160px",
    // textAlign: "center",
    // background: "#364d79",
}

const SampleNextArrow = (props: React.AllHTMLAttributes<HTMLElement>) => {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                color: "black",
                // fontSize: "100px", height: "100px", width: "100px"
            }}
            onClick={onClick}
        />
    )
}

const SamplePrevArrow = (props: React.AllHTMLAttributes<HTMLElement>) => {
    const { className, style, onClick } = props
    return <div className={className} style={{ ...style, display: "block", color: "black" }} onClick={onClick} />
}
const Dot = (props: React.AllHTMLAttributes<HTMLElement>) => {
    const { className, style, onClick } = props
    return (
        <div
            className={className + "bottom-"}
            // style={{ ...style, display: "block", color: "black" }}
            // onClick={onClick}
        />
    )
}
const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // appendDots: () => <Dot />,
}

function Experience() {
    const { t, i18n } = useTranslation()
    const screens = useBreakpoint()
    const breakPoint = getBreakPoint(screens)

    const props: React.AllHTMLAttributes<HTMLElement> = {
        className:
            "w-[14rem] 2xs:w-[22rem] sm:w-[35rem]  md:w-[30rem] xl:w-[40rem] \
        h-[90%]  ",
    }
    //2xs:h-[75%] sm:h-[70%] md:h-[90%]
    return (
        // <div className="space-y-5 md:space-y-8" style={{ animation: "fadeIn 0.5s ease-in-out" }}>
        //     <div className="relative container md:mx-auto text-center py-10 md:py-16">
        //         <div className="space-y-5 xl:space-y-8">
        //             <div className="text-left">
        //                 <div className="text-2xl md:text-3xl font-extrabold">About Tit Tar Man</div>
        //                 <div className="text-lg md:text-xl mt-5">
        //                     Tit Tar Man is Malaysia's leading bone setting specialist that combines Chinese Medicine Arts and Modernised Western
        //                     methods for relief of bone, joints, and muscle pains. Founder Sifu Derrick Siew has over 24 years of experience in
        //                     treatment and bone setting and aims to help patients gear towards a healthy lifestyle.{" "}
        //                 </div>
        //             </div>
        //             <div className="space-y-8 md:space-y-10">
        //                 <div className="grid grid-cols-12 items-center bg-ttm-yellow-900">
        //                     <div className="text-left col-span-12 md:col-span-7 grid order-1 md:order-0 p-10">
        //                         <div className="text-xl md:text-2xl font-extrabold">Malaysia Book of Records</div>
        //                         <div className="md:text-lg mt-5">
        //                             Tit Tar Man broke the Malaysia Book of Records having The Most Bone Setting Treatment in an Event, successfully
        //                             treating 1,269 patients all within an unbelievable time frame of just 4 days. Our breakthrough Signature Full Body
        //                             Alignment Treatment combines Western and TCM therapy that has helped many patients solve all their pains and
        //                             misalignment issues, minor and major! Crick, crack, experience a pain-free life now! just like our 43,968 patients
        //                             served over the years!
        //                         </div>
        //                     </div>
        //                     <img
        //                         alt="about us"
        //                         srcSet="/_next/image?url=%2Fimages%2Fabout-us%2Fawards_02.jpg&amp;w=640&amp;q=75 1x, /_next/image?url=%2Fimages%2Fabout-us%2Fawards_02.jpg&amp;w=1080&amp;q=75 2x"
        //                         src="/_next/image?url=%2Fimages%2Fabout-us%2Fawards_02.jpg&amp;w=1080&amp;q=75"
        //                         width="500"
        //                         height="500"
        //                         decoding="async"
        //                         data-nimg="1"
        //                         className="mx-auto w-full col-span-12 md:col-span-5 grid order-0 md:order-1 p-5 wow animate__ animate__fadeInRight animated"
        //                         style={{ color: "transparent", visibility: "visible", animationName: "fadeInRight" }}
        //                     />
        //                 </div>
        //                 <div className="grid grid-cols-12 items-center bg-gray-300">
        //                     <img
        //                         alt="about us"
        //                         srcSet="/_next/image?url=%2Fimages%2Fabout-us%2Fawards_03.jpg&amp;w=640&amp;q=75 1x, /_next/image?url=%2Fimages%2Fabout-us%2Fawards_03.jpg&amp;w=1080&amp;q=75 2x"
        //                         src="/_next/image?url=%2Fimages%2Fabout-us%2Fawards_03.jpg&amp;w=1080&amp;q=75"
        //                         width="500"
        //                         height="500"
        //                         decoding="async"
        //                         data-nimg="1"
        //                         className="mx-auto w-full col-span-12 md:col-span-5 grid order-0 md:order-1 p-5 wow animate__ animate__fadeInLeft animated"
        //                         style={{ color: "transparent", visibility: "visible", animationName: "fadeInLeft" }}
        //                     />
        //                     <div className="text-left col-span-12 md:col-span-7 grid order-1 md:order-0 p-10">
        //                         <div className="text-xl md:text-2xl font-extrabold">Asia Pacific Book Of The Top</div>
        //                         <div className="md:text-lg mt-5">
        //                             Another milestone &amp; achievement for Tit Tar Man (TTM) as we were awarded "Asia Pacific Super Health Brand
        //                             2022-2023" by the Asia Pacific Top Excellence Brand. A very significant moment for us as we are recognised for our
        //                             dedication and contribution towards the Art of Bone Setting and Traditional Medicine. We are deeply thankful to
        //                             all of our patients who make this possible. Let us continue to take your pain away. Crick Crack, pain go away
        //                             today!
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className="grid grid-cols-12 items-center bg-ttm-yellow-900">
        //                     <div className="text-left col-span-12 md:col-span-7 grid order-1 md:order-0 p-10">
        //                         <div className="text-xl md:text-2xl font-extrabold">Lions Club International</div>
        //                         <div className="md:text-lg mt-5">
        //                             It's a day to Celebrate! Malaysia Lion Club of Selangor TCM has awarded our #TitTarMan Founder, Sifu Derrick Siew
        //                             with the highest recognition (杰出中医狮王大奖) LIONS TCM KING AWARD. Under the supervision of our Sifu Derrick
        //                             Siew, our fellow masters have also been awarded with the (卓越成就贡献奖) LIONS TCM EXCELLENCE AWARD for their
        //                             outstanding skills together with their passion and services towards the Art of Bone Setting and Traditional
        //                             Medicine.
        //                         </div>
        //                     </div>
        //                     <img
        //                         alt="about us"
        //                         srcSet="/_next/image?url=%2Fimages%2Fabout-us%2Fawards_01.jpg&amp;w=640&amp;q=75 1x, /_next/image?url=%2Fimages%2Fabout-us%2Fawards_01.jpg&amp;w=1080&amp;q=75 2x"
        //                         src="/_next/image?url=%2Fimages%2Fabout-us%2Fawards_01.jpg&amp;w=1080&amp;q=75"
        //                         width="500"
        //                         height="500"
        //                         decoding="async"
        //                         data-nimg="1"
        //                         className="mx-auto w-full col-span-12 md:col-span-5 grid order-0 md:order-1 p-5 wow animate__ animate__fadeInRight animated"
        //                         style={{ color: "transparent", visibility: "visible" }}
        //                     />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="space-y-5 md:space-y-8 max-h-[600vh] pb-12" style={{ animation: "fadeIn 0.5s ease-in-out" }}>
            <div
                className="h-[5rem] 2xs:h-[7.5rem] lg:h-[20rem] xl:h-[25rem] mb-20 relative ps-[15%] pt-[5%]"
                style={{ backgroundSize: "cover", backgroundImage: `url(${images["Experience_Banner.png"]})` }}
            />

            <div className="md:mx-auto my-auto text-center ">
                <Divider className="font-bold text-2xl mb-12 lg:mb-16">{t("experience.Title1")}</Divider>
                <div
                    className=" flex flex-row justify-center
                                    w-[100%] 
                                    h-[100rem] sm:h-[20rem] md:h-[25rem] lg:h-[35rem] xl:h-[40rem]
                                    "
                    // style={{ border: "1px solid red" }}
                >
                    {/* <div> */}
                    <div
                        className="flex flex-row flex-wrap  justify-around w-[80%] h-[100rem] sm:h-[20rem] md:h-[28rem] lg:h-[40rem] rounded_image_mask"
                        // style={{ border: "1px solid green" }}
                    >
                        {/* <div
                            className="w-[80%] sm:w-[25%] h-[30rem] sm:h-[75%] shadow-2xl drop-shadow-xl border-2 border-slate-400 rounded-xl "
                            style={{ backgroundSize: "cover", backgroundImage: `url(${images["Experience_Process_1.png"]})` }}
                            ></div>
                                                <div
                            className="w-[80%] sm:w-[25%] h-[30rem] sm:h-[75%] shadow-2xl drop-shadow-xl border-2 border-slate-400 rounded-xl "
                            style={{ backgroundSize: "cover", backgroundImage: `url(${images["Experience_Process_2.png"]})` }}
                            ></div>
                            <div
                            className="w-[80%] sm:w-[25%] h-[30rem] sm:h-[75%] shadow-2xl drop-shadow-xl border-2 border-slate-400 rounded-xl "
                            style={{ backgroundSize: "cover", backgroundImage: `url(${images["Experience_Process_3.png"]})` }}
                            ></div> 
                        */}
                        <div className="w-[80%] sm:w-[25%] lg:w-[30%] xl:w-[25%] 2xl:w-[22%] h-[30rem] sm:h-[75%] ">
                            <Image
                                className="shadow-2xl drop-shadow-xl border-2 border-slate-400 rounded-xl "
                                src={images["Experience_Process_1.png"]}
                            />
                        </div>

                        <div className="w-[80%] sm:w-[25%] lg:w-[30%] xl:w-[25%] 2xl:w-[22%] h-[30rem] sm:h-[75%] ">
                            {" "}
                            <Image
                                className="shadow-2xl drop-shadow-xl border-2 border-slate-400 rounded-xl "
                                src={images["Experience_Process_2.png"]}
                            />
                        </div>
                        <div className="w-[80%] sm:w-[25%] lg:w-[30%] xl:w-[25%] 2xl:w-[22%] h-[30rem] sm:h-[75%] ">
                            {" "}
                            <Image
                                className="shadow-2xl drop-shadow-xl border-2 border-slate-400 rounded-xl "
                                src={images["Experience_Process_3.png"]}
                            />
                        </div>
                    </div>
                    {/* <Image src={images["Experience_Process_1.png"]} className="w-[80%]" preview={false} style={{ border: "1px solid red" }} /> */}
                    {/* </div>
                    <div> */}
                    {/* <Image src={images["Experience_Process_2.png"]} className="w-[80%]" preview={false} style={{ border: "1px solid red" }} /> */}
                    {/* </div>
                    <div> */}
                    {/* <Image src={images["Experience_Process_3.png"]} className="w-[80%]" preview={false} style={{ border: "1px solid red" }} /> */}
                    {/* </div> */}
                </div>
                <Divider className="font-bold text-2xl mb-0 lg:mb-0">{t("experience.Title2")}</Divider>
                <Carousel
                    arrows
                    infinite={false}
                    {...settings}
                    // dots={{ className: "bg-black" }}
                    // slidesToShow={breakPoint !== "xxl" && breakPoint !== "xl" && breakPoint !== "lg" ? 1 : 2}
                    slidesToShow={1}
                    className="mx-auto px-5 bg-slate-50 my-12 py-6 lg:py-12"
                    style={{ boxShadow: "inset 0 0 40px 5px rgba(255,255,255,0.8)" }}
                >
                    {[...Array(10).keys()].map((i) => {
                        if (i !== 0)
                            return (
                                <div key={`Experience_case_${i}`}>
                                    <div
                                        className="container 
                                    w-[100%] xl:w-[70%]
                                    h-[13rem] 2xs:h-[20rem] md:h-[28rem] lg:h-[30rem]
                                    "
                                        // style={{ border: "1px solid red" }}
                                    >
                                        <Card
                                            imageUrl={[images[`Experience_case_${i}_be4.png`], images[`Experience_case_${i}_after.png`]]}
                                            description={t(`experience.Case.${i}`)}
                                            prop={props}
                                        />
                                    </div>
                                </div>
                            )
                    })}
                </Carousel>
            </div>

            <Divider className="font-bold text-2xl">{t("nav.Reviews")}</Divider>
            <Review />
        </div>
    )
}

export default Experience
