import footer_img from "./img/banner.jpeg"
import { FacebookFilled, YoutubeFilled, EnvironmentFilled, WhatsAppOutlined, FacebookOutlined, createFromIconfontCN } from "@ant-design/icons"
import { Divider, Typography, Button, Image } from "antd"
import { useTranslation } from "react-i18next"
import WazeIcon from "./img/WazeIcon.png"
import { importAll } from "./usefulScripts"
import { CNIconFont, FacebookCustomIcon, TikTokCustomIcon, XHSCustomIcon } from "./CustomIcon"

const { Title, Text } = Typography

const images = importAll(require.context("./img", false, /Footer.*/)) as { [key: string]: string }

function MyFooter() {
    const { t, i18n } = useTranslation()

    return (
        <>
            <div className="relative flex justify-content-center my-5">
                <div
                    className="w-[80vw] h-[55rem] lg:h-[20rem] grid grid-cols-12 gap-y-6 sm:gap-y-12 lg:gap-y-0  gap-x-6 lg:gap-x-16 2xl:gap-x-32"
                    // style={{ border: "1px solid red" }}
                >
                    <div className="col-span-12 lg:col-span-5" style={{ border: "0px solid red" }}>
                        <Title level={4} className="mb-3">
                            {t("footer.About.Title")}
                        </Title>

                        <div>{t("footer.About.Text")}</div>
                    </div>

                    {/* <div className="col-span-12 md:col-span-3 flex flex-col align-items-start" style={{ border: "0px solid red" }}>
                        <Title level={4} className="mb-3">
                            {t("footer.More.Title")}
                        </Title>

                        <Button className="font-semibold ps-0" type="link" href="./">
                            Home
                        </Button>
                        <Button className="font-semibold ps-0" type="link" href="./about">
                            About
                        </Button>
                        <Button className="font-semibold ps-0" type="link" href="./treatments">
                            Treatments
                        </Button>
                        <Button className="font-semibold ps-0" type="link" href="./specialty">
                            Specialty
                        </Button>
                        <Button className="font-semibold ps-0" type="link" href="./testimonies">
                            Testimonies
                        </Button>
                        <Button className="font-semibold ps-0" type="link" href="./contact">
                            Contact
                        </Button>
                    </div> */}

                    <div className="relative col-span-12 lg:col-span-2 flex flex-col align-items-start h-[8rem] md:h-100">
                        <Title level={4} className="mb-3">
                            {t("footer.Branch.Title")}
                        </Title>

                        <Text className="font-semibold mt-1 mb-2">Shah Alam</Text>
                        <Text className="font-semibold">Kelantan</Text>
                        <div className="absolute bottom-[-3rem] lg:bottom-[-12rem] flex flex-row align-items-center">
                            <Button
                                type="link"
                                icon={
                                    <FacebookCustomIcon
                                        // className="fill-cyan-700 hover:fill-cyan-600"
                                        style={{
                                            fontSize: "1.75rem",
                                            // fill: "#EA940C",
                                            // backgroundColor: "#EA940C",
                                            // borderRadius: "15px",
                                        }}
                                    />
                                }
                                href="https://www.facebook.com/EfengSpineHealingCenter/"
                                target="_blank"
                                className="mr-2"
                            />
                            <Button
                                type="link"
                                icon={<YoutubeFilled style={{ fontSize: "2rem", color: "red" }} />}
                                href="https://www.youtube.com/channel/UCKFNBxVQG8_oThb_SWQ17EA"
                                target="_blank"
                                className="mx-2"
                            />

                            <Button
                                type="text"
                                icon={<TikTokCustomIcon style={{ fontSize: "1.65rem" }} />}
                                href="https://www.tiktok.com/@efengspine?_t=8pIewnTVkCl&_r=1"
                                target="_blank"
                                className="mx-2"
                            />
                            <Button
                                type="link"
                                icon={<XHSCustomIcon style={{ fontSize: "1.85rem" }} />}
                                href="https://www.xiaohongshu.com/user/profile/5be0179a64009c00014e8819?xhsshare=CopyLink&appuid=5be0179a64009c00014e8819&apptime=1725009726&exSource="
                                target="_blank"
                                // className="mx-2"
                            />
                        </div>
                    </div>

                    <div
                        className="col-span-12 lg:col-span-5 items-center ms-0 lg:ms-5 w-80 md:w-[26rem]"
                        // style={{ border: "1px solid red" }}
                    >
                        <Image src={images["Footer_Banner_1.png"]} className=" shadow rounded-sm" style={{ border: "0px solid red" }} />
                    </div>
                    <Text className="text-start absolute bottom-0 text-xs float-left">
                        EFENG SPINE HEALING CENTER © {new Date().getFullYear()}. All Rights Reserved.
                    </Text>
                </div>
            </div>
        </>
    )
}

export default MyFooter
