import { useTranslation } from "react-i18next"
import { getBreakPoint, importAll } from "./usefulScripts"
import { Button, Grid, Tabs, Typography, type TabsProps } from "antd"
import { useLang } from "./App"
import { SignatureIcon } from "./CustomIcon"

const images = importAll(require.context("./img", false, /Banner_[0-9].*/)) as { [key: string]: string }
const verifiedIcon = require("./img/VerifiedIcon.png")

const { Text, Paragraph, Title } = Typography
const { useBreakpoint } = Grid

function Home(
    {
        // currentTheme,
        // }: {
        // currentTheme: { algorithm: (token: SeedToken) => MapToken }
    }
) {
    const { t, i18n } = useTranslation()
    const { lang } = useLang()

    const screens = useBreakpoint()
    const breakPoint = getBreakPoint(screens)

    const tabData: TabsProps["items"] = [
        {
            label: <div className="text-lg">{i18n.t("product.Concept.Label")}</div>,
            key: "1",
            children: (
                <div className="text-gray-500 text-xs lg:text-sm">
                    <div className="text-black text-lg mb-2">{i18n.t("product.Concept.Title")}</div>
                    <div>{i18n.t("product.Concept.Para1")}</div>
                    <br />
                    <div>{i18n.t("product.Concept.Para2")}</div>
                    <br />
                    <div>{i18n.t("product.Concept.Para3")}</div>
                    <br />
                    <div>{i18n.t("product.Concept.Para4")}</div>
                </div>
            ),
        },
        {
            label: <div className="text-lg">{i18n.t("product.Theory.Label")}</div>,
            key: "2",
            children: (
                <div className="text-gray-500 text-xs lg:text-sm">
                    <div className="text-black text-lg mb-2">{i18n.t("product.Theory.Title")}</div>
                    <div>{i18n.t("product.Theory.Para1")}</div>
                    <br />
                    <div>{i18n.t("product.Theory.Para2")}</div>
                    <br />
                    <div>{i18n.t("product.Theory.Para3")}</div>
                    <br />
                    <div>{i18n.t("product.Theory.Para4")}</div>
                    <div>{i18n.t("product.Theory.Para5")}</div>
                </div>
            ),
        },
        {
            label: <div className="text-lg">{i18n.t("product.Uniqueness.Label")}</div>,
            key: "3",
            children: (
                <div className="text-gray-500 text-xs lg:text-sm">
                    <div className="text-black text-lg mb-2">{i18n.t("product.Uniqueness.Title")}</div>
                    <div className="mb-4">
                        <div className="text-black text-base lg:text-md mb-1">{i18n.t("product.Uniqueness.SubTitle1")}</div>
                        {i18n.t("product.Uniqueness.SubTitle1_Para")}
                    </div>
                    <div className="mb-4">
                        <div className="text-black text-base lg:text-md mb-1">{i18n.t("product.Uniqueness.SubTitle2")}</div>
                        {i18n.t("product.Uniqueness.SubTitle2_Para")}
                    </div>
                    <div className="mb-4">
                        <div className="text-black text-base lg:text-md mb-1">{i18n.t("product.Uniqueness.SubTitle3")}</div>
                        {i18n.t("product.Uniqueness.SubTitle3_Para")}
                    </div>
                    <div className="mb-4">
                        <div className="text-black text-base lg:text-md mb-1">{i18n.t("product.Uniqueness.SubTitle4")}</div>
                        {i18n.t("product.Uniqueness.SubTitle4_Para")}
                    </div>
                    <div className="mb-4">
                        <div className="text-black text-base lg:text-md mb-1">{i18n.t("product.Uniqueness.SubTitle5")}</div>
                        {i18n.t("product.Uniqueness.SubTitle5_Para")}
                    </div>
                </div>
            ),
        },
    ]

    const onTabChange = () => {
        document.querySelector(".ant-tabs-content-holder")!.classList.add("fadeIn")
        setTimeout(function () {
            document.querySelector(".ant-tabs-content-holder")!.classList.remove("fadeIn")
        }, 500)
    }

    return (
        <section id="home" className="mySection" style={{ animation: "fadeIn 0.5s ease-in-out" }}>
            <section
                className={`relative 
                flex justify-content-center
                ${lang === "zh" ? "h-[40rem]" : "h-[43.5rem]"} sm:h-[48rem] md:h-[48rem] lg:h-[44rem] 
                px-2 `}
                style={{
                    backgroundImage: `url(${
                        breakPoint === "xxl" || breakPoint === "xl" || breakPoint === "lg"
                            ? images["Banner_0_landscape.png"]
                            : images["Banner_0_potrait.png"]
                    })`,
                    width: "100vw",
                    // position: "relative",
                    // border: "1px solid yellow",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    overflow: "hidden",
                }}
            >
                {/* screen lg and above */}
                <div
                    className="hidden lg:block 
                    lg:w-[45%] xl:w-5/12 2xl:w-4/12 
                    h-[100%]
                    left-[50%] xl:left-[50%] 2xl:left-[55%]
                    "
                    style={{
                        position: "absolute",
                        backgroundImage: `url(${images["Banner_0_human.png"]})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                />

                <div className="hidden lg:block w-[95vw] xl:w-full py-32 xl:py-32 2xl:py-32 ">
                    <div className="container mx-auto">
                        <div className="w-full mx-auto xl:w-11/12 2xl:w-11/12">
                            <div className="w-full lg:w-6/12">
                                <h1
                                    className="w-full lg:w-12/12 text-3xl leading-none text-cyan-700 font-playfair lg:text-5xl xl:text-7xl 2xl:text-7xl mb-4"
                                    // style={{ border: "1px solid red" }}
                                >
                                    {t("home.MainTitle").toUpperCase()}
                                </h1>
                                <h3
                                    className="my-2 text-lg leading-normal text-black lg:my-4 lg:text-2xl xl:text-3xl 2xl:text-4xl mb-4"
                                    style={{ fontFamily: "HanSerifSC-Regular" }}
                                >
                                    {t("home.Name")}
                                </h3>
                                <div className="flex flex-wrap mb-4">
                                    <div className="flex flex-row items-center justify-between mb-2 text-lg font-normal leading-normal text-black xl:text-xl 2xl:text-2xl ">
                                        <img src={verifiedIcon} className="me-2" style={{ height: "4vh" }} />
                                        {t("home.Bullet1")}
                                    </div>
                                    <div className="flex flex-row items-center justify-between mb-2 text-lg font-normal leading-normal text-black xl:text-xl 2xl:text-2xl ">
                                        <img src={verifiedIcon} className="me-2" style={{ height: "4vh" }} />
                                        {t("home.Bullet2")}
                                    </div>
                                    <div className="flex flex-row items-center justify-between mb-2 text-lg font-normal leading-normal text-black xl:text-xl 2xl:text-2xl ">
                                        <img src={verifiedIcon} className="me-2" style={{ height: "4vh" }} />
                                        {t("home.Bullet3")}
                                    </div>
                                </div>
                                <Button
                                    type="primary"
                                    className="px-8 py-5 text-lg text-white rounded-full lg:px-10 lg:float-left"
                                    style={{ fontFamily: "HanSerifSC-Regular" }}
                                    onClick={() =>
                                        window.open(
                                            "https://api.whatsapp.com/send?phone=60133237828&text=Hi,%20I%20would%20like%20to%20know%20more!",
                                            "_blank"
                                        )
                                    }
                                >
                                    {t("home.Button")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* --------- screen md and below --------- */}
                <div
                    className="absolute md:block lg:hidden 
                    w-[17rem] xs:w-6/12 sm:w-6/12 md:w-5/12 
                    h-[20rem] 2xs:h-[21rem] xs:h-[22rem] sm:h-[28rem] md:h-[28rem]
                    bottom-[0rem] 
                    right-[4%] 2xs:right-[8%] sm:right-[10%] md:right-[15%]"
                    style={{
                        // border: "1px solid red ",
                        backgroundImage: `url(${images["Banner_0_human.png"]})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                />

                <div
                    className="absolute md:block lg:hidden 
                    bottom-[-32rem] 2xs:bottom-[-32rem] xs:bottom-[-31rem] sm:bottom-[-30rem] md:bottom-[-30rem]                   
                    right-[71%] sm:right-[75%] md:right-[65%] cursor-default"
                    style={
                        {
                            // border: "0.5px solid red ",
                        }
                    }
                >
                    {/* <img src={Signature} /> */}
                    <Button type="link" icon={<SignatureIcon className="w-[28rem] xs:w-[30rem] sm:w-[40rem] cursor-default" />} />
                </div>
                <div
                    className={`md:block lg:hidden absolute top-5 sm:top-0  ${lang === "zh" ? "md:top-10" : ""}
                w-[91vw] 2xs:w-[88vw] sm:w-[90vw] md:w-[65vw] py-12`}
                    style={{ border: "0px red solid" }}
                >
                    <div className="w-full mx-auto mb-5">
                        <div className="w-full mx-2 ">
                            <h1
                                className={`block w-full text-4xl  ${
                                    lang === "zh" ? "sm:text-5xl" : "sm:text-4xl"
                                } leading-none text-cyan-700 font-playfair mb-3`}
                            >
                                {t("home.MainTitle").toUpperCase()}
                            </h1>
                            <h3 className="ps-5 pt-2 text-xl leading-normal text-black" style={{ fontFamily: "HanSerifSC-Regular" }}>
                                {t("home.Name")}
                            </h3>
                        </div>
                    </div>
                    {/* </div> */}
                    <div className="md:block lg:hidden  mb-5" style={{ border: "0px solid red " }}>
                        <div className="d-flex flex-column align-items-start mb-2 ">
                            <div className="flex flex-row items-center justify-between mb-2  sm:text-lg lg:text-xl font-normal leading-normal text-black ">
                                <img src={verifiedIcon} className="me-2 h-[3vh] lg:h-[4vh]" />
                                {t("home.Bullet1")}
                            </div>
                            <div className="flex flex-row items-center justify-between mb-2  sm:text-lg lg:text-xl font-normal leading-normal text-black ">
                                <img src={verifiedIcon} className="me-2 h-[3vh] lg:h-[4vh]" />
                                {t("home.Bullet2")}
                            </div>
                            <div className="flex flex-row items-center justify-between mb-2  sm:text-lg lg:text-xl font-normal leading-normal text-black ">
                                <img src={verifiedIcon} className="me-2 h-[3vh] lg:h-[4vh]" />
                                {t("home.Bullet3")}
                            </div>
                        </div>
                        <Button
                            type="primary"
                            className="ms-1 px-8 py-5 text-lg text-white rounded-full lg:px-10 lg:float-left"
                            style={{ fontFamily: "HanSerifSC-Regular" }}
                            onClick={() =>
                                window.open(
                                    "https://api.whatsapp.com/send?phone=60133237828&text=Hi,%20I%20would%20like%20to%20know%20more!",
                                    "_blank"
                                )
                            }
                        >
                            {t("home.Button")}
                        </Button>
                    </div>
                </div>
            </section>

            <section className="w-full py-16 ms-2 sm:ms-0 px-2">
                <div className="container w-full md:w-[75%] mx-auto">
                    <div className="w-full mx-auto xl:w-11/12 lg:text-lg">
                        <div className="mb-6">
                            <h3 className="text-xl font-normal leading-none text-center  lg:text-2xl text-siteBg-200 mb-2">{t("home.About")}</h3>
                            <h2
                                className="text-cyan-600 mb-2 text-4xl leading-none text-center lg:text-5xl 2xl:text-6xl font-playfair text-siteBg-100"
                                style={{ fontFamily: "HanSerifSC-Regular" }}
                            >
                                {t("home.Name").toUpperCase()}
                            </h2>
                            <h6 className="text-lg font-semibold text-center ">{t("home.MainTitle")}</h6>
                        </div>
                        <div className="indent-5 lg:indent-0 text-left lg:text-center text-gray-500">
                            {lang === "zh" ? (
                                <>
                                    {t("home.LastName")}是一名<span className="font-semibold">资深{t("home.MainTitle")}</span>，于
                                    <span className="font-semibold">2015</span>
                                    年创立筋骨还原，把健康传承世界各地。 到现在已经帮助了超过<span className="font-semibold"> 34000 </span>
                                    名以上的患者得到健康。
                                </>
                            ) : (
                                <>
                                    {t("home.Name")} is a <span className="font-semibold"> {t("home.MainTitle")}</span>, established Efeng Spine
                                    Healing Center in
                                    <span className="font-semibold"> 2015 </span>carrying the legacy of health around the world. To date, he has
                                    helped over<span className="font-semibold"> 34,000 </span>
                                    patients to achieve better health.
                                </>
                            )}
                            <br />
                            <div className="w-full md:w-[25rem] lg:w-[42rem] mx-auto mt-5" style={{ border: "0px solid red" }}>
                                <div
                                    className="text-base lg:text-2xl text-center font-bold italic text-black mb-2"
                                    style={{ fontFamily: "Gyreadventor" }}
                                >
                                    {t("home.Quote")}
                                </div>

                                <div className="text-xs lg:text-base font-bold text-right" style={{ fontFamily: "HanSerifSC-Regular" }}>
                                    - {t("home.Name")}
                                </div>
                            </div>
                            <br />
                            {t("home.Description3")}
                        </div>
                        <div className="flex flex-wrap items-start align-items-start w-full mt-6 lg:my-6" style={{ border: "0px solid red" }}>
                            <div className="flex lg:pr-6 lg:w-1/2" style={{ border: "0px solid red" }}>
                                <img src={images["Banner_1.png"]} className=" mb-6 rounded-md lg:mb-0" />
                            </div>
                            <div className="w-full lg:pl-6 lg:w-1/2" style={{ border: "0px solid red" }}>
                                {lang === "zh" ? (
                                    <ul className="pl-4 mb-6 leading-relaxed list-disc">
                                        <li className="font-extrabold pb-2 pl-2">
                                            2015<span className="font-normal">{t("home.Qualification1")}</span>
                                        </li>
                                        <li className="font-extrabold pb-2 pl-2">
                                            2019<span className="font-normal">{t("home.Qualification2")}</span>
                                        </li>
                                        <li className="font-extrabold pb-2 pl-2">
                                            2020<span className="font-normal">{t("home.Qualification3")}</span>
                                        </li>
                                        <li className="font-extrabold pb-2 pl-2">
                                            2021<span className="font-normal">{t("home.Qualification4")}</span>
                                        </li>
                                        <li className="font-extrabold pb-2 pl-2">
                                            2022<span className="font-normal">{t("home.Qualification5")}</span>
                                        </li>
                                        <li className="font-extrabold pl-2">
                                            2023<span className="font-normal">{t("home.Qualification6")}</span>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul className="pl-4 mb-6 leading-relaxed list-disc">
                                        <li className="font-extrabold pb-2 pl-2">
                                            <span className="font-normal">{t("home.Qualification1")}</span> 2015
                                        </li>
                                        <li className="font-extrabold pb-2 pl-2">
                                            <span className="font-normal">{t("home.Qualification2")}</span> 2019
                                        </li>
                                        <li className="font-extrabold pb-2 pl-2">
                                            <span className="font-normal">{t("home.Qualification3")}</span> 2020
                                        </li>
                                        <li className="font-extrabold pb-2 pl-2">
                                            <span className="font-normal">{t("home.Qualification4")}</span> 2021
                                        </li>
                                        <li className="font-extrabold pb-2 pl-2">
                                            <span className="font-normal">{t("home.Qualification5")}</span> 2022
                                        </li>
                                        <li className="font-extrabold pl-2">
                                            <span className="font-normal">{t("home.Qualification6")}</span> 2023
                                        </li>
                                    </ul>
                                )}
                                <p className="text-gray-500">{t("home.Description4")}</p>
                                <ul className="mt-4 pl-4 mb-6 leading-relaxed list-disc ">
                                    <li className="pb-2 pl-2">{t("home.Skill1")}</li>
                                    <li className="pb-2 pl-2">{t("home.Skill2")}</li>
                                    <li className="pb-2 pl-2">{t("home.Skill3")}</li>
                                    <li className="pb-2 pl-2">{t("home.Skill4")}</li>
                                    <li className="pb-2 pl-2">{t("home.Skill5")}</li>
                                    <li className="pb-2 pl-2">{t("home.Skill6")}</li>
                                    <li className="pb-2 pl-2">{t("home.Skill7")}</li>
                                    <li className="pb-2 pl-2">{t("home.Skill8")}</li>
                                    <li className="pl-2">{t("home.Skill9")}</li>
                                </ul>
                            </div>
                        </div>
                        <p className="indent-5 lg:indent-24 text-gray-500">{t("home.Description5")}</p>
                        <div className="d-flex justify-content-center pb-6 mt-5">
                            <Button
                                type="primary"
                                className="px-8 py-5 text-lg text-white rounded-full lg:px-10 lg:float-left"
                                style={{ fontFamily: "HanSerifSC-Regular" }}
                                onClick={() =>
                                    window.open(
                                        "https://api.whatsapp.com/send?phone=60133237828&text=Hi,%20I%20would%20like%20to%20know%20more!",
                                        "_blank"
                                    )
                                }
                            >
                                {t("home.Button")}
                            </Button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="containerw-full md:w-[75%] mx-auto px-5 pb-24">
                <div className="d-flex justify-content-center px-4 reveal">
                    <Tabs
                        tabPosition={`${breakPoint === "sm" || breakPoint === "xs" ? "top" : "left"}`}
                        items={tabData}
                        onChange={onTabChange}
                        className="productTab"
                    />
                </div>
            </section>
        </section>
    )
}

export default Home
