const translationZH = {
    nav: {
        Home: "主页",
        About: "关于",
        Treatment: "治疗",
        Specialty: "专长",
        Experience: "案例",
        Contact: "咨询",

        Philosophy: "理念",
        Product: "服务",
        Reviews: "患者评语",
        FAQ: "常见问题",
        PDF: "手册下载",
    },

    home: {
        MainTitle: "脊椎骨资深专科医师",
        Name: "谢易峰医师",
        LastName: "谢医师",
        Bullet1: "脊椎骨和脊椎神经系统资深导师的治疗专家",
        Bullet2: "通过骨科技术和手法，以减轻疼痛并促进康复效果",
        Bullet3: "重建各个部位的关节、筋膜和脊椎骨，改善身体功能",
        Button: "立即咨询谢医师",

        About: "关于",
        Description1_1: "是一名",
        Description1_2: "年创立筋骨还原，把健康传承世界各地。 到现在已经帮助了超过 34000 名以上的患者得到健康。",
        Description2: "To date, he has helped over 34,000 patients to achieve better health.",
        Quote: `"只要身体没有痛感，也没有精神的痛苦，我们就能享受更充实的生活。"`,
        Description3:
            "筋骨还原治疗把错位的筋骨还原到原本的位置，帮助患者重新获得健康和活力。 谢医师认为，一个人失去健康，会让全家人陷入困境，同样的一个人获得健康，全家人也会幸福和谐。 实际上谢医师是在帮助人们打造一个健康的未来。",

        Qualification1: "年筋骨还原疗法创始人",
        Qualification2: "年骨骼医美高级培训导师",
        Qualification3: "年筋骨医疗高级培训师",
        Qualification4: "年脊椎神经系统资深导师",
        Qualification5: "年骨科医疗高级培训师",
        Qualification6: "年多家骨伤科特邀顾问",

        Description4: "谢医师的专业特长领域：",
        Skill1: "椎间盘突出症治疗",
        Skill2: "髋关节和膝关节治疗",
        Skill3: "脊椎侧弯治疗",
        Skill4: "关节炎和风湿病治疗",
        Skill5: "膝关节半月板损伤治疗",
        Skill6: "O/X型脚矫正治疗",
        Skill7: "骨盆塑形治疗",
        Skill8: "面部骨骼医美治疗",
        Skill9: "五十肩和肩周炎治疗",

        Description5:
            "除了临床工作外，谢医师还是一名心灵康复顾问，致力于为社会贡献志愿服务。他在社区中积极参与各种公益活动，通过分享心理健康知识和提供心灵支持，帮助人们改善情绪和心理状态。谢医师不仅关注病患的身体健康，也关心他们的心灵成长，用实际行动为社会带来温暖和关怀。",
    },

    treatment: {
        Title: "我们提供的服务",
        TreatmentDataKeys: {
            Orthopaedics: "骨骼矫正科",
            "Orthopaedics and Joint": "骨与关节外科",
            neurology: "神经系统内科",
        },
        TreatmentData: {
            "Spinal-Nervous-System": {
                Title1: "脊椎神经系统",
                Intro: "脊椎神经系统是我们身体的神经系统的一个重要部分，它包括脊髓和从脊髓发出的神经。简单来说，脊椎神经系统负责传递大脑与身体其他部分之间的信息。",
                Title2: "脊椎神经系统出现问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "神经病变：脊椎压迫或其他代谢性疾病可能导致周围神经病变，影响脊椎神经系统的功能。",
                    2: "创伤或事故：例如车祸、跌倒或运动损伤可能导致脊髓损伤或脊神经受压。",
                    3: "椎间盘突出：椎间盘中的软物质突出，压迫脊神经根，可能导致疼痛、麻木或肌肉无力。",
                    4: "",
                },
                Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "持续或加剧的疼痛：如果神经受到持续压迫或损伤，可能会导致长期、严重的疼痛，这种疼痛可能难以通过普通的止痛药缓解。",
                    2: "运动功能障碍：如果脊髓或脊神经受到压迫，可能会导致肌肉无力、协调失调或瘫痪。这可能影响到日常活动，如走路、拿东西等。",
                    3: "",
                    4: "",
                },
            },
            Scoliosis: {
                Title1: "脊椎侧弯",
                Intro: "脊椎侧弯，也称为脊柱侧弯（scoliosis），是指脊柱在正常的直线位置上发生了侧向弯曲。简单来说，就是脊柱不再笔直而是向一侧弯曲，形成“C”或“S”形状。",
                Title2: "导致脊椎侧弯问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "先天性脊柱侧弯：这种情况是由于脊柱在胎儿发育过程中出现异常，比如脊柱椎骨发育不完全或融合，从而导致脊柱弯曲。",
                    2: "长时间不良姿势：长时间保持不良坐姿站姿（如含胸、驼背或偏向一侧的姿势）可能对脊柱施加不均衡的压力，从而加重已有的脊柱侧弯或引发其他脊柱问题。",
                    3: "过度使用单侧身体：比如长时间单侧背负重物（如单肩背包）或从事单侧运动（如单侧投掷或提举重物）可能会导致脊柱的负担不均，影响其正常的对齐。",
                    4: "",
                },
                Title3: "脊椎侧弯恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "外观变化：侧弯加重可能导致身体外观变化，如肩膀或臀部不对称，或者胸部突出。",
                    2: "日常活动受限：由于疼痛、活动受限和体形变化，患者的日常生活质量可能受到影响，可能导致心理和情绪问题，如焦虑和抑郁。",
                    3: "",
                    4: "",
                },
            },
            Hunchback: {
                Title1: "驼背",
                Intro: "驼背是一种脊柱的异常弯曲，使上背部（胸椎区域）向外隆起，形成明显的弯曲，类似骆驼的背部，因此取名“驼背”。这会导致身体上半部看起来向前弯曲，肩膀向前倾斜。",
                Title2: "出现驼背问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "姿势不良：长期保持不良坐姿或站姿，如含胸、驼背。",
                    2: "先天性畸形：出生时脊柱的异常发育。",
                    3: "长期不良坐姿：长时间坐在不适合的椅子上，尤其是含胸或弯腰坐姿，可能导致脊柱的异常弯曲。",
                    4: "",
                },
                Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "慢性背痛：随着驼背的恶化，背部的弯曲加重，可能导致持续的背痛或腰痛。",
                    2: "胸腔压迫：严重的驼背可能压迫胸腔，限制肺部的扩张，导致呼吸困难、胸闷或疲劳。",
                    3: "",
                    4: "",
                },
            },
            "Dowager-Hump": {
                Title1: "富贵包",
                Intro: `“富贵包”是老百姓起的一个吉利名，在古代的时候通常出现在大富大贵的人家身上，“富贵包”也可能是你的夺命包，这个吉利的名字的背后潜藏的却是性命攸关的健康危机，出现的区域是在颈部和肩的脂肪积累或隆起。`,
                Title2: "出现富贵包问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "姿势不良：长期的低头、含胸姿势可能促使毒素在颈部和肩部堆积，影响体态。",
                    2: "不良坐姿：长期坐姿不正确，尤其是工作时长时间保持不良姿势，也可能导致毒素堆积和颈部瘀血隆起。",
                    3: "脊椎变形：骨质疏松或脊椎退化可能导致脊椎变形，从而影响脂肪的分布和堆积，形成富贵包。",
                    4: "",
                },
                Title3: "富贵包恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "脑血管负担：如果富贵包导致了颈椎的严重压迫，可能对脑部血管产生额外的负担，尤其是在已有脑血管疾病史的人群中。这种压迫可能影响血液循环，增加中风或其他脑血管事件的风险。",
                    2: "胸腔压迫：在极端情况下，富贵包可能导致肩部的脂肪堆积压迫胸腔，从而限制肺部扩张，可能引发呼吸困难，特别是在体力活动时",
                    3: "",
                    4: "",
                },
            },
            "Frozen-Shoulder": {
                Title1: "肩周炎",
                Intro: `肩周炎就是肩部周围的组织发炎，导致肩部疼痛、僵硬和活动受限。简单来说，就是肩膀的关节和周围的肌肉、韧带等出现了问题，让你感觉到不舒服或者不能像以前那样灵活地活动肩膀。`,
                Title2: "出现肩周炎问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "长时间维持同一姿势：例如长时间坐在电脑前，或者长时间保持某种姿势，可能导致肩部肌肉和关节的紧张和发炎。",
                    2: "重复运动：长期重复性肩部运动（如举重、频繁提物、做某些工作）会使肩部软组织疲劳，进而引发炎症。",
                    3: "急性损伤：肩部受撞击、跌倒或其他外力伤害（如肩膀脱臼或骨折）可能引起肩部软组织的炎症。",
                    4: "",
                },
                Title3: "肩周炎恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "夜间疼痛：疼痛可能在夜间加重，使得睡眠质量下降。",
                    2: "冻结肩：恶化的肩周炎可能发展为冻结肩（粘连性关节囊炎），这会导致肩部极度僵硬和活动受限。",
                    3: "二次伤害：由于肩部的活动受限，可能导致其他关节或肌肉的过度使用，从而增加受伤的风险。",
                    4: "",
                },
            },
            Kyphosis: {
                Title1: "探颈",
                Intro: `“探颈”是一种体态姿势，指的是人在站立或坐着时，把头部向前探出来，通常是为了看得更清楚或者调整姿势。简单来说，就是把脖子伸长，头往前探的动作。`,
                Title2: "出现探颈问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "姿势不良：长时间坐姿不正确，比如低头看手机或电脑，容易导致探颈姿势。",
                    2: "不良习惯：一些日常活动，如看电视、用电脑时的习惯，可能导致探颈。",
                    3: "颈部疼痛或僵硬：颈部的疼痛或僵硬可能使人不自觉地探颈来寻找舒适的位置。",
                    4: "专注或紧张：在专注工作或感到紧张时，可能无意识地探颈。",
                },
                Title3: "探颈恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "僵硬感：颈部会变得僵硬，限制头部的正常运动范围。",
                    2: "持续疼痛：长时间的探颈姿势可能导致颈部肌肉和韧带的持续性疼痛。",
                    3: "颈椎病变：长期探颈可能加速颈椎退化，导致颈椎病变，进一步压迫神经根。",
                    4: "",
                },
            },
            "Imbalance-Shoulder": {
                Title1: "高低肩",
                Intro: `“高低肩”指的是两边肩膀的高度不一致。简单来说，就是一个肩膀比另一个肩膀高。比如，当你站直时，一个肩膀可能会比另一个肩膀高一点。`,
                Title2: "出现高低肩问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "单侧负重：经常单肩背包或提重物，可能导致一侧肩膀的肌肉紧张或拉长，从而导致肩膀高度不一。",
                    2: "肩部受伤：肩部的扭伤、拉伤或骨折可能导致肩膀高度不一。",
                    3: "长期不良姿势：长时间保持不良坐姿或站姿，比如长期低头看手机或电脑，可能导致一侧肩膀变得更高。",
                    4: "",
                },
                Title3: "高低肩恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "运动功能下降：肩膀高度不一致，可能影响到肩部的稳定性和力量，影响运动表现。",
                    2: "神经问题：长期的高低肩可能导致神经受到压迫，导致手臂麻木、刺痛或无力。",
                    3: "",
                    4: "",
                },
            },
            "Trigger-Finger": {
                Title1: "弹弓指",
                Intro: `“弹弓指”是一种手指的外观问题，指的是手指的关节在伸直时呈现出“弓形”的弯曲，看起来像弹弓一样。这种情况通常是由于手指关节的软组织受损或变形导致的。`,
                Title2: "出现弹弓指问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "先天性问题：某些人可能天生就有手指关节的结构问题，导致手指外观异常。",
                    2: "关节炎：如类风湿性关节炎等关节炎症状可能导致关节变形和弹弓指。",
                    3: "受伤或损伤：手指的韧带、肌腱或关节受损可能导致手指形状的改变。",
                    4: "肌腱问题：手指肌腱的损伤或失去弹性导致手指无法正常伸展，形成弹弓指。",
                },
                Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "日常困难：执行日常任务，如扣扣子、使用工具等，可能变得更加困难。",
                    2: "关节不适：手指的关节可能感到僵硬或不适，特别是在清晨或长时间不活动后。",
                    3: "手部功能下降：手部的总体功能可能下降，影响到手部的力量和灵活性。",
                    4: "",
                },
            },
            "Bone-Spur": {
                Title1: "骨刺",
                Intro: `骨刺是指骨头表面出现的额外骨质生长，通常表现为突起或尖端。这些突起是由于骨头在关节、脊柱或其他部位受到长期摩擦、压力或损伤后，为了适应这些变化而自然形成的。骨刺可以导致周围组织的压迫，引起疼痛、炎症和运动受限。`,
                Title2: "出现骨刺问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "关节磨损：关节长期受磨损或受压力，骨头可能在关节周围形成骨刺以适应这些变化。",
                    2: "年龄相关：随着年龄增长，关节和骨头的变化可能会导致骨刺的形成。",
                    3: "受伤或过度使用：骨头受伤或关节长期过度使用也可能导致骨刺形成。",
                    4: "",
                },
                Title3: "骨刺恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "走路困难：骨刺位于脊柱或脚跟处时，可能影响步态，导致行走时的疼痛或不稳定感。",
                    2: "持续疼痛：骨刺的增生可能导致持续的疼痛，尤其是在受压或活动时更为明显。",
                    3: "",
                    4: "",
                },
            },
            "Gelatinous-Knee": {
                Title1: "膝盖胶质",
                Intro: `膝盖胶质其实就是一种在膝关节里起润滑和缓冲作用的物质。它主要是由胶原蛋白和其他成分组成的，能够帮助膝盖在运动时减少摩擦，保护骨头和关节。简单来说，它就像是膝盖里的一个“润滑油”，让你的膝盖更顺滑地运作。`,
                Title2: "膝盖胶质出现问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "年龄因素：随着年龄的增长，胶质的质量和数量可能下降，导致膝盖关节的润滑和缓冲效果减弱。",
                    2: "关节炎：如骨关节炎或类风湿关节炎，这些疾病会破坏膝盖的胶质，导致疼痛和僵硬。",
                    3: "过度使用：长时间的重复运动或剧烈运动会对膝盖施加过多压力，可能导致胶质受损。",
                    4: "",
                },
                Title3: "膝盖胶质恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "关节疼痛：胶质恶化会导致膝关节润滑不足，摩擦增多，从而引发持续的疼痛和不适。",
                    2: "运动受限：由于疼痛和僵硬，膝盖的运动能力会下降，影响走路、爬楼梯或跑步等活动。",
                    3: "",
                    4: "",
                },
            },
            "Pelvic-Readjustment": {
                Title1: "骨盆修复",
                Intro: `骨盆修复简单来说就是帮助骨盆恢复正常状态的过程。骨盆修复包括治疗骨盆骨折、纠正骨盆倾斜或骨盆缩小。这个过程的目标是让骨盆恢复到正常的位置和功能，减轻疼痛，提高活动能力。`,
                Title2: "骨盆出现问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "孕产因素：怀孕和分娩过程中的荷尔蒙变化和胎儿的压力可能导致骨盆的结构发生变化。",
                    2: "姿势不良：长时间坐姿或站姿不正确，会导致骨盆前倾或后倾，从而影响骨盆的对齐。",
                    3: "肥胖：过重的体重增加了骨盆的负担，可能引起骨盆的变形或倾斜。",
                    4: "",
                },
                Title3: "骨盆恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "尿路和生殖系统问题：骨盆问题可能影响膀胱和生殖器官的位置和功能，导致尿失禁、月经不规律或其他相关症状。",
                    2: "关节问题：长期的骨盆恶化可能导致髋关节、膝关节等其他关节的额外负担，增加关节疼痛或磨损的风险。",
                    3: "",
                    4: "",
                },
            },
            "Sciatic-nerve": {
                Title1: "坐骨神经",
                Intro: `坐骨神经是从腰部到腿部的一条大神经。它是人体最长的神经，从腰部的脊椎开始，一直到腿部后侧。它负责传递感觉和控制腿部的肌肉。简单来说，坐骨神经就像是腿部的“电线”，帮助你的大脑和腿部沟通，控制腿部的动作和感觉。`,
                Title2: "坐骨神经出现问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "长期不良姿势：长时间坐姿或站姿不当，会增加对坐骨神经的压力，导致问题。",
                    2: "脊柱狭窄：脊柱内的空间变窄，压迫神经根，影响坐骨神经。",
                    3: "骨盆问题：如骨盆倾斜或变形，可能导致坐骨神经受压。",
                    4: "",
                },
                Title3: "坐骨神经恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "剧烈的腿部疼痛：疼痛通常从下背部开始，沿着腿部的后侧或外侧放射，可能非常剧烈和刺痛。",
                    2: "腿部麻木或刺痛：坐骨神经问题可能导致腿部感觉异常，如麻木、刺痛或针刺感。",
                    3: "",
                    4: "",
                },
            },
            "Ear-fluid-imbalance": {
                Title1: "耳水不平衡",
                Intro: `耳水不平衡简单来说，就是内耳里负责平衡的液体出现了问题。这种液体在内耳的两个主要部位——耳蜗和前庭——中帮助控制身体的平衡和头部的位置。如果液体量过多或过少，或者分布不均，就会导致你感到头晕、平衡失调或眩晕。`,
                Title2: "出现耳水不平衡问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "压力变化：如快速的气压变化，可能干扰耳内液体的平衡，导致头晕或不适。",
                    2: "药物副作用：某些药物，特别是那些影响内耳功能的药物，可能导致耳水不平衡。",
                    3: "C2 颈椎压迫 ：颈椎部位的骨骼压迫或旋位也会导致耳水不平衡的问题出现。",
                    4: "",
                },
                Title3: "耳水不平衡恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "情绪困扰：长期的眩晕和不适可能引发焦虑、抑郁等情绪问题，影响心理健康。",
                    2: "严重眩晕：感觉自己或周围的环境在旋转，可能使你难以站立或走路。",
                    3: "听力下降：恶化的耳水不平衡可能会影响听力，导致听觉受损或听力丧失。",
                    4: "",
                },
            },
            "Lumbar-vertebrae-from-L1-to-L5": {
                Title1: "腰椎 L1 - L5",
                Intro: `腰椎就是你腰部的脊椎骨。它们是你脊椎中间的几个骨头，负责支撑上半身的重量，同时允许你做弯腰、转身等动作。腰椎的健康对保持正确的姿势和运动功能非常重要。腰椎又分为 L1 - L5。`,
                Title2: "腰椎出现问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "长时间久坐或姿势不良：长期坐着工作或保持不良姿势会给腰椎带来额外的压力，引发不适。",
                    2: "过度使用或扭伤：搬重物、剧烈运动或突然扭动腰部可能导致腰椎受伤或肌肉拉伤。",
                    3: "椎间盘突出：椎间盘的软垫突出并压迫神经，可能导致腰部疼痛和腿部症状。",
                    4: "",
                },
                Title3: "腰椎恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "运动受限：腰部的灵活性降低，弯腰、转身、抬腿等动作变得困难。",
                    2: "脊柱变形：严重的腰椎恶化可能引起脊柱侧弯或其他变形，进一步影响身体的稳定性和功能。",
                    3: "",
                    4: "",
                },
            },
            "Thoracic-vertebrae-T1-T12": {
                Title1: "胸椎 T1 - T12",
                Intro: `胸椎就是你背部的脊椎骨。它们位于脊柱的中间部分，连接在胸部的区域。胸椎的主要作用是支撑上半身的重量，并保护脊髓，同时帮助你弯腰和转身。它们也连接了你的肋骨，帮助形成胸腔。 L1 - L5。`,
                Title2: "胸椎出现问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "创伤或外伤：胸部或背部的意外伤害，如车祸或摔倒，可能损伤胸椎。",
                    2: "椎间盘突出：虽然椎间盘突出通常发生在腰椎，但它也可能发生在胸椎，导致疼痛和神经受压。",
                    3: "脊柱侧弯：胸椎区域的脊柱侧弯（如青少年脊柱侧弯症）可能导致胸椎问题和不适。",
                    4: "",
                },
                Title3: "胸椎恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "神经问题：胸椎的压迫或损伤可能影响到神经，导致手臂或上身的麻木、刺痛感或无力。",
                    2: "呼吸困难：由于胸椎问题可能影响到胸腔的正常扩展，导致呼吸不畅或胸部不适。",
                    3: "",
                    4: "",
                },
            },
            "Cervical-vertebrae-C1-C7": {
                Title1: "颈椎 C1 - C7",
                Intro: `颈椎就是你脖子里的脊椎骨。它们位于脊柱的上部，连接头部和上半身。颈椎的主要作用是支撑头部的重量，允许你转动和弯曲脖子，并保护脊髓在颈部的位置。总的来说，颈椎帮助你移动脖子，同时保持脊柱的稳定。 L1 - L5。`,
                Title2: "颈椎出现问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "颈部受伤：如车祸或运动中的撞击，可能导致颈椎损伤或扭伤。",
                    2: "姿势不良：长时间低头看手机或电脑，或坐姿不正确，会给颈椎带来额外压力，导致不适。",
                    3: "肌肉紧张：颈部肌肉过度紧张或劳损可能导致颈椎的疼痛和僵硬。",
                    4: "",
                },
                Title3: "颈椎恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "持续的颈部疼痛：疼痛可能加重，甚至放射到肩膀、上背部或头部。",
                    2: "眩晕或平衡问题：严重的颈椎问题可能影响到血液流动或神经功能，引发头晕或平衡问题。",
                    3: "手臂和手部症状：颈椎问题可能压迫到神经，导致手臂或手部的麻木、刺痛或无力感。",
                    4: "",
                },
            },
            "Feet-with-OX-deformity": {
                Title1: "O/X 型脚",
                Intro: `X 型脚（也叫内八字）：当你站直时，膝盖内侧接触或者靠得很近，而脚踝外侧的距离较大。也就是说，膝盖弯曲向内，而脚踝向外分开。O 型脚（也叫外八字）：当你站直时，膝盖外侧有明显的空隙，而脚踝内侧的距离较近。也就是说，膝盖向外弯曲，而脚踝靠得较近。 L1 - L5。`,
                Title2: "出现 O/X 型脚问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "长时间工作：长时间坐姿或站立工作会给膝盖施加额外压力，可能导致膝盖内侧的过度弯曲，增加关节损伤的风险。",
                    2: "发育问题：在儿童时期，腿部骨骼的发育异常可能导致O/X型脚。",
                    3: "过度使用：长期站立或步态不良可能导致膝盖外侧的压力增加，形成O/X型脚。",
                    4: "",
                },
                Title3: "O/X 型脚恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "膝盖疼痛：由于膝关节外侧或内侧的压力增加，可能导致膝盖疼痛和不适。",
                    2: "走路困难：步态不正常可能导致行走时的不稳定性或不适。",
                    3: "姿势问题：可能导致整体姿势不良，影响到脊柱和其他关节的对齐。",
                    4: "",
                },
            },
            Paralysis: {
                Title1: "麻痹",
                Intro: `麻痹就是身体的一部分失去感觉或运动能力。你可能会感到某个地方不再能动，或者没有感觉到触碰。比如，你的手可能因为压迫神经而变得麻木，或者你可能感觉到手臂无力，动不了。麻痹可以发生在身体的任何部位，通常是因为神经受到压迫、受损或疾病导致的。`,
                Title2: "出现麻痹问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "神经压迫：如椎间盘突出或骨刺压迫到神经，可能导致麻痹。",
                    2: "神经损伤：如外伤或手术中神经受损，可能导致麻痹。",
                    3: "中风：脑部的血流中断导致某些身体部位的麻痹或无力。",
                    4: "椎间盘突出：脊柱中的椎间盘突出可能压迫到附近的神经，导致麻痹。通常发生在颈椎或腰椎区域。",
                },
                Title3: "脊椎神经系统恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "肌肉萎缩：长期的麻痹可能导致肌肉萎缩或变弱，因为肌肉得不到正常的使用和锻炼。",
                    2: "感觉丧失加重：麻痹的区域可能会失去更多的感觉，完全无法感知触摸、温度或疼痛。",
                    3: "",
                    4: "",
                },
            },
            Headache: {
                Title1: "头痛",
                Intro: `头痛就是你头部感觉到不舒服或疼痛。它可以有不同的表现，可能是钝痛、刺痛或脉动感，通常发生在头部的任何地方。`,
                Title2: "出现头痛问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "颈部问题：如颈椎病或肌肉紧张，可能导致头部疼痛或不适。",
                    2: "C1 颈椎压迫：颈椎部位的骨骼压迫或旋位也会导致头痛的问题出现。",
                    3: "C1 颈椎压迫：颈椎部位的骨骼压迫或旋位也会导致有失眠的情况发生，而失眠后往往都会感到头痛。",
                    4: "",
                },
                Title3: "头痛恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "功能受限：剧烈的头痛可能导致无法正常进行日常活动，如工作、学习或社交活动。",
                    2: "视力问题：严重的头痛（特别是偏头痛）可能影响视力，导致视力模糊或出现视觉干扰。",
                    3: "",
                    4: "",
                },
            },
            Migraine: {
                Title1: "偏头痛",
                Intro: `偏头痛是一种常见的头痛类型，通常表现为头部一侧的剧烈、搏动性疼痛。它可能持续数小时到数天，并且往往伴有其他症状，如恶心、呕吐、对光或声音敏感。`,
                Title2: "出现偏头痛问题的原因有很多，以下是一些常见的因素：",
                Reasons: {
                    1: "颈部和肩部问题：颈部或肩部的紧张和不适可能触发或加重偏头痛。",
                    2: "颈椎问题：颈椎的退化性疾病（如颈椎病、椎间盘突出）可能导致颈部的神经受到压迫或刺激，从而引发或加重偏头痛。颈部的肌肉紧张也可能触发头痛。",
                    3: "C1 颈椎压迫：颈椎部位的骨骼压迫或旋位也会导致偏头痛的问题出现。",
                    4: "",
                },
                Title3: "偏头痛恶化可能导致一系列严重的后果：",
                Consequences: {
                    1: "功能受限：剧烈的头痛可能导致无法正常进行日常活动，如工作、学习或社交活动。",
                    2: "视力问题：严重的头痛（特别是偏头痛）可能影响视力，导致视力模糊或出现视觉干扰。",
                    3: "",
                    4: "",
                },
            },
        },
    },

    about: {
        Title: "关于易峰筋骨还原",
        // Founder: "筋骨还原创始人",
        // Name: "姓 名 ：谢 易 峰 医 师",
        // Description1: "敬天爱人 德才兼备 以德为先",
        // Description2: "是筋骨还原创办人主要的思想",
        // Description3: "想病人所想，急病人所急，",
        // Description4: "爱惜生命，关爱患者",
        Para1: "易峰筋骨还原创始人谢易峰医师，原名谢伟龙出生于马来西亚雪邦，双溪比力，是中国，新加坡，马来西亚赫赫有名的中医师谢医师从小就不断的看到身边的人总是被病痛折磨，也发现每一个家庭里只要一个人失去健康，全家人陷入困境。。。",
        Para2: "于是就对着老天发愿了一个愿望，希望能成为一名医师，帮助每一个人获得健康但是。。当时家境面临经济困难的状况，想要出国学医需要很庞大资金，于是就边打工边存款存到资金后就开始出国学医。刚开始学了，整骨，推拿，针灸，铁打，chiropactic,小颜术，拿到行医证后，就开始治疗患者那段期间，医好了很多病人，心情很喜悦，但是，随着病人越来越多，遇到的很多奇怪的杂症，一百个患者，只有七十个康复，而另外三十个就无法康复病情也开始恶化那个时候，谢易峰医师非常的失落。他一心想帮助这些痛苦的患者，但却无能为力，心情到了非常的低落，真心希望这三十个患者也可以得到康复，健康。",
        Para3_title: "简介",
        Para3_1: {
            p1: "但是谢易峰医师不放弃，谢易峰医师深信，这个世界那么大，总会有一种治疗一定会让每一个人得到康复，健康的于是谢易峰医师将所有的储蓄，都用来不断的出国进修，哪怕是一个患者医师都想用尽所有的全力治好他，但那个时候。。。医师背负着庞大的学费问题，陷入了经济状况只能靠着面包和快熟面生活，家里人得知后，遭到家人的反对出国进修，面对着金钱的问题，面对着家人的反对，还遇到了课程收了昂贵的费用，却得不到任何有用的知识，煎熬的面临着生活的开销，只能白天打工，晚上上门做治疗赚取生活费，碎片时间就 看书学习就这样工作，吃饭，治疗，学习，反复着。",
            p2: "后来可能是上天的慈悲吧，开始行好运了，慢慢的遇到好的老师，认识了很多名医，感恩老师们，加上经过很多的亲身临床经验和很多的名医指导后谢易峰医师发现了，原来我们每个人的身体都有一种规律，只要人的筋与骨还原到原本的位置，大部分的酸，痛，麻，都可获得大大的改善甚至包括内科疾病比如",
        },
        Para3_2: "偏头痛",
        Para3_3: "失眠",
        Para3_4: "眩晕",
        Para3_5: "胃",
        Para3_6: "胸闷",
        Para3_7: "，的等等,都会得到康复的效果。太棒了终于，经过一番的努力，帮助到了更多的人得到康复，花了不少心思后，于2015年谢易峰医师创办了",
        Para3_8: "筋骨还原疗法",
        Para3_9: "。",
        Para3_10: "谢易峰医师经过了种种成就与经历",
        Para3_11: "筋骨还原疗法",
        Para3_12: "到至今已经帮助了超过",
        Para3_13: "34000",
        Para3_14:
            "名以上的患者得到了健康，最后也帮助了很多家庭得到和谐，一个人失去健康，全家人陷入困境，同样的，一个人获得健康，全家人幸福和谐 ，未来还会有更多人受益。",
    },

    experience: {
        Title1: "资深经验",
        Title2: "案例",
        Case: {
            1: "治疗前, 我的右手臂骨骼错位, 导致半脱臼, 上半身筋膜紧绷、皱褶, 右边肩胛骨疼痛, 右手拇指弹钢琴时用不上力, 右手举不高。\
                经过谢医师的治疗后, 我的右手臂骨骼已经复位, 手臂活动自如更加灵活了。 \
                特别是通过 “筋骨还原疗法” 我的右手臂从无法举高到完全康复, 筋骨还原疗法不仅缓解了我的疼痛, 还使我恢复了正常的活动能力, 让我重新找回了生活的自信。",
            2: "曾经画画时手臂受伤很痛，即使吃止痛药也不见好转。但经过治疗，我终于感到手臂恢复自如，现在能自由创作了。",
            3: "当我来到这里，我终于找到了解决我举手不高问题的方法！\
                由于我时常跌倒，导致我的全身都变得紧绷不舒服，我的左侧从腰到脚都感觉到痛。\
                有一次跌倒时，我用左手肘来支撑自己，结果左手也痛得无法举起，我的父母觉得情况严重，因为我的手已经举不起来了。\
                幸运的是，我来到易峰筋骨还原，经过谢医师的诊断，找到病因。\
                治疗后，我的手终于可以完全举高了。",
            4: "SETELAH TREATMENT\
                SELURUH BADAN SAYA RASA SANGAT SELESA DAN RINGAN",
            5: "我的手没有力，拿杯水喝都会很用力到发抖，全身很紧绷、疼痛，双脚走路无力，双脚麻痹冰冷，每天穿了2双袜子还是感觉到脚很冷。\
                我的情况已经很多年了，通过信徒的介绍遇到了筋骨还原。\
                经过谢易峰医师治疗后，原本没有办法提高的手，现在我的手可以自如的提高了，目前双脚的冰冷和麻痹也已经康复90%了",
            6: "Sakit tulang belakang saya telah menghadapi masalah ini selama 3 tahun. \
                Saya kesulitan tidur di malam hari karena rasa sakit yang mendera, bahkan merasa mual dan ingin muntah , membuat saya tidak bisa tenang. \
                Setelah menjalani pemeriksaan pemulihan tulang dan otot oleh Efeng Spine Healing Center, akhirnya saya menemukan akar penyebabnya , dan nyeri dapat mereda setelah mendapatkan pengobatan.",
            7: "我的膝盖痛了很久，走也痛，坐也痛\
                我去医院检查，医生让我开刀，我不要。\
                后来朋友介绍我来这里做治疗，现在膝盖好了，我非常开心。",
            8: "一直以来我觉得我的脸部很四方，经过谢易峰医师调整我的下颌骨后，\
                我开始越来越喜欢我的脸部了，非常推荐！",
            9: "经过治疗后，我的背部明显变得更加挺直了。\
                当我去学校后，老师和同学都问我：“怎么突然看你变高了啊？”听到这些话，我感到莫名其妙的开心",
        },
    },

    product: {
        Concept: {
            Label: "原理",
            Title: "筋骨还原原理",
            Para1: "筋骨还原是一种针对人体骨骼、肌肉、关节等组织的康复理疗方法，其原理主要是通过特定的手法和技巧，重建骨骼、肌肉和关节的生物力学平衡，从而达到缓解疼痛、促进康复的效果。",
            Para2: "筋骨还原原理的基础是人体的骨骼、肌肉和关节是紧密相连的，它们之间的关系在人体的运动和功能方面起着至关重要的作用。如果骨骼、肌肉和关节之间存在生物力学的不平衡，就会导致疼痛、僵硬、失调等问题，影响身体的正常运动和功能。",
            Para3: "筋骨还原的手法和技巧可以通过对身体的姿势、运动、生理状况等因素的综合考虑，针对不同的问题和症状，采用不同的调整手法，以重建骨骼、肌肉和关节的生物力学平衡，从而改善疼痛、增强运动能力、促进康复。",
            Para4: "筋骨还原通过手法和技巧重建人体骨骼、肌肉和关节的生物力学平衡，从而达到缓解疼痛、促进康复的效果。",
        },

        Theory: {
            Label: "理论",
            Title: "筋骨还原理论",
            Para1: "筋骨还原理论是一种治疗身体不适和疼痛的方法，由马来西亚的一位名叫谢易峰医师创立。",
            Para2: "这种理论认为，身体各部分之间的筋膜、神经组织韧带、肌肉和骨骼是相互关联的，如果其中一个部分出现问题，就会对整个身体造成影响。",
            Para3: "筋骨还原理论通过手法矫正身体各部分的关节，筋膜和脊椎骨，以达到改善身体功能、减轻疼痛的目的。这种手法通常使用脊椎仪器或手法在患者身上施加轻压或拉伸。",
            Para4: "这种方法可以用于治疗各种疾病，如颈椎病、腰椎病、肩周炎、关节炎、坐骨神经、椎间盘突出，偏头痛、失眠、消化不良等等。",
            Para5: "但需要注意的是，且需要由经过专业培训的筋骨还原医师或治疗师进行操作，以确保治疗效果和安全性。",
        },

        Uniqueness: {
            Label: "独特性",
            Title: "筋骨还原独特性",
            SubTitle1: "1. 非手术治疗",
            SubTitle1_Para: "筋骨还原理论采用脊椎仪器或手法等进行治疗，不需要进行手术治疗，避免了手术风险和恢复期的痛苦。",
            SubTitle2: "2. 个性化治疗",
            SubTitle2_Para:
                "筋骨还原理论是针对个体进行治疗的，因为每个人的身体结构和问题不同，治疗方法也需要针对性地调整，因此可以根据患者的具体情况进行个性化的治疗，增加了治疗的有效性。",
            SubTitle3: "3. 没有副作用",
            SubTitle3_Para: "相对于其他药物治疗或手术治疗，筋骨还原理论的治疗方法较为温和，没有任何副作用，不会对患者的身体产生负面影响。",
            SubTitle4: "4. 短期见效",
            SubTitle4_Para: "部分疾病在筋骨还原理论治疗下可以迅速缓解症状，例如肌肉疼痛、关节僵硬等问题。",
            SubTitle5: "5. 适用范围广",
            SubTitle5_Para:
                "筋骨还原理论适用于多种疾病，包括颈椎病、腰椎病、肩周炎、关节炎、头痛、失眠、消化不良等等，因此可以应用于多个领域，满足患者不同的治疗需求。",
        },

        cases: {
            Title: "案例",
            1: {
                Title: "脊椎侧弯",
                Content1: "患者：X-RAY报告医生说我患上了脊椎侧弯症，脊椎需要7万5500令吉手术费，当时我想到医生要用刀在我身上割开，我很害怕！",
                Content2:
                    "后来通过亲戚介绍，知道了易峰筋骨还原，经过5次的脊椎侧弯治疗，我的脊椎明显直了很多，也好看了很多。非常感谢筋骨还原治疗，让我在无需手术的情况下恢复正常的身体。",
            },
            2: {
                Title: "驼背",
                Content:
                    "妈妈：“我儿子驼背严重，两边肩膀往内缩，不抬头挺胸，一副驼背的样子，担心孩子担心儿子是不是有什么骨科疾病，不晓得会不会终身驼背。经过医师诊断后，确认这是结构性驼背，经过一次治疗后，驼背真的很明显直很多。",
            },
            3: {
                Title: "坐骨神经痛",
                Content: "由于坐骨神经痛导致下背无力的症状无法下蹲，治疗后可以轻松蹲下了",
            },
            4: {
                Title: "面部骨骼",
                Content1: "一直以来我觉得我的脸部很四方，经过医师的调整我的下额骨后，",
                Content2: "我开始越来越喜欢我的脸部了，非常推荐！",
            },
            5: {
                Title: "五十肩",
                Content1: "Tangan saya tidak dapat angkat tinggi kerana sangat sakit, dan saya tidak dapat ikat rambut saya.",
                Content2: "Setelah treatment pertama, tangan saya sudah sembuh dan tidak sakit lagi.",
            },
            6: {
                Title: "膝关节炎",
                Content:
                    "我的膝盖疼了很久，走也疼，坐也疼。我去医院检查，医生让我开刀，我不要，后来朋友介绍我来这里做治疗，现在膝盖好了，我非常开心。",
            },
        },
    },

    review: {
        Title: "易峰筋骨还原",
        1: {
            Text1: "经过朋友的推荐，试试来医师这里治疗。长期的背疼让我无法睡好，做了几次治疗后疼痛明显改善。本身也因为曾经跌倒，盆骨高低导致长短脚问题，久站盆骨出现疼痛，治疗后也有明显改善。谢谢医师。",
        },
        2: {
            Text1: "经过谢易峰医师的治疗后，我的疼痛问题真的减少很多，尤其是肩膀酸痛，改善很多颈椎本来需要开刀的，现在也好起来了，不再紧绷，我很感谢谢易峰医师，让我恢复正常的日常生活。",
            Text2: "本身也因为曾经跌倒，盆骨高低导致长短脚问题，久站盆骨出现疼痛，治疗后也有明显改善。谢谢医师。",
        },
        3: {
            Text1: "我已经痛苦一年了！X-Ray报告医生说我L1-L5有歪，曾经试过打3次止痛针，吃止痛药都没有好起来，通过Facebook知道了易峰筋骨还原医师，经过3次的治疗，我的腰酸背痛，小腿到脚裸到拇指麻痛的问题，就得到了明显的改善。",
            Text2: "现在我的腰不酸痛了，小腿，拇指麻痹消失了，本来坐着半个小时就会酸疼麻痹，现在久坐都没有问题了。我非常感谢谢易峰医师，让我可以恢复正常的生活，不再痛苦。",
        },
        4: {
            Text1: "经过医师的治疗后，我的筋骨不会感觉拉扯，家人，朋友们都说我的身体已经挺直了很多，身体也不会向前倾，双脚也可以伸直了，以前睡觉必须卷着身体才能入睡，现在完全可以平躺睡着了，医师的治疗技术非常值得推荐，谢谢医师的治疗。",
        },
    },

    contact: {
        Title: "筋骨还原疗程步骤",
        Banner: {
            Title: "欢迎联系我们",
            Shop: "易峰筋骨还原",
        },
        Consultation: "咨询",
        Consultation_Descript: "点击WhatsApp，专业顾问将提供更详细的了解。",

        Appointment: "预约",
        Appointment_Descript1: "安排预约时间给医师诊断",
        Appointment_Descript2: "无需长时间等候",

        Diagnosis: "诊断",
        Diagnosis_Descript: "问诊病情，进行健康检查，或诊断疾病。",

        Explanation: "讲解",
        Explanation_Descript: "向患者详细讲解，身体健康状况以及筋骨还原治疗的过程。",

        Cost: "费用",
        Cost_Descript: "根据个性化的治疗方案，让患者能够清晰了解自己需要的支付费用。提供合理透明的价格信息。",

        Treatment: "治疗",
        Treatment_Descript: "根据患者的病情和诊断结果，因为每个人的身体结构和问题不同，医师定制个性化治疗方案进行治疗，达到预期的治疗效果。",

        Follow_up: "后续",
        Follow_up_Descript: "根据治疗效果和患者的反应，进行后续观察和治疗，包括调整治疗计划、给予康复指导和建议等。",
    },

    faq: {
        Title: "常见问题",
        1: {
            Question: "我去筋骨还原看病之前需要预约吗？",
            Ans1: "需要，您需要安排预约。请打电话或WhatsApp联系我们。我们将为您指派一位筋骨还原医疗顾问，他或她将在预约的各个步骤中为您提供协助。",
            Ans2: "在您到达之前，指定的顾问将尽力安排好您需要的所有检查和程序。",
        },
        2: {
            Question: "预约需要多久？",
            Ans: "我们将尽可能以及尽快帮助您安排预约时间。您的预约日期将取决于您的筋骨还原医师的时间以及您自己的需求和偏好。",
        },
        3: {
            Question: "我可以申请预约想见的医师吗？",
            Ans: "可以。欢迎您申请预约特定的医师。您可以放心，该筋骨还原专家将为您的病症提供权威的专业知识。",
        },
        4: {
            Question: "我需要向筋骨还原提供我的医疗记录吗？",
            Ans: "需要—我们的医师需要对您的病情有清楚的了解。需要您的医疗记录，包括医师治疗史、手术报告（提供医疗程序的详细情况）。",
        },
        5: {
            Question: "谁可以在筋骨还原接受治疗？",
            Ans: "成人、老人，儿童以及婴儿都可以在筋骨还原接受治疗。决定您是否适合在这接受治疗将取决于您的疾病或诊断，以及其它医疗信息等因素。您可以打电话或WhatsApp联系我们，询问或预约咨询。",
        },
        6: {
            Question: "什么是个性化治疗？",
            Ans: "根据不同的伤情，年龄，身体状况等因素，定制适合您的个性化治疗方案。",
        },
        7: {
            Question: "费用是多少？",
            Ans: "因为每个人的身体结构和症状不同，所以价格不同。筋骨还原以合理的收费标准，提供透明的价格信息，治疗前先让患者能够清晰了解自己需要支付的费用。",
        },
        8: {
            Question: "是否能够提供折扣？",
            Ans: "很遗憾，我们不提供折扣。我们明白，对国际患者来说，预先计划好开支十分重要。我们致力于为您提供最准确的预估费用，避免意外开支。",
        },
        9: {
            Question: "我去筋骨还原看病需要带什么文件？",
            Ans: "您的身份证或护照。",
        },
        10: {
            Question: "治疗后我需要注意什么？",
            Ans: "筋骨还原医师或治疗师提供详细的治疗前和治疗后护理指导，以确保治疗的安全和成功，帮助患者尽快恢复。",
        },
        11: {
            Question: "筋骨还原的效果如何？",
            Ans: "只需配合医师和治疗师康复方案，筋骨还原能够有效治疗伤病，恢复健康，达到预期的治疗效果。",
        },
        12: {
            Question: "安全保障如何？",
            Ans: "筋骨还原需要经过专业医师和治疗师进行操作，患者能够得到专业，安全的治疗。",
        },
    },

    footer: {
        About: {
            Title: "关于易峰筋骨还原",
            Text: "易峰筋骨还原是一家结合传统的中医理论与现代医学技术，为每一位患者量身定制治疗方案。我们的目标是通过科学的疗法和专业的护理，帮助患者恢复身体的正常功能，提高生活质量。",
        },
        More: {
            Title: "链接",
        },
        Branch: { Title: "分店" },
        Community: "社区",
        QRCode: {
            2: "小红书",
        },
        Address: "地址",
    },
}

export default translationZH
